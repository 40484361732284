@import '../../scss/variables.scss';

.balance-page {
    padding-top: 10px;
    padding-bottom: 10px;

    * {
        cursor: default;
    }

    h1 {
        margin: 2px 20px 5px 0;
        width: 274px;
    }

    .page-header {
        margin-bottom: 10px;
        padding-bottom: 0;
        & > div{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
    }

    & > div {
        background: #100311;
        padding: 10px 20px;
        #balance-table {
            min-width: 304px;
            max-width: 373px;
            margin-left: 15px;
            height: max-content;
            tbody > tr {
                // &:nth-child(1) td, &:nth-child(2)  td{
                //     @extend .-positive;
                // }

                // &:nth-child(3)  td{
                //     @extend .-negative;
                // }
                
                td {
                    text-align: right;
                    padding: 4px 6px;
                }
                th {
                    padding: 4px 10px;
                }
            }
        }
    }
}
