.position-taking {
    padding-top: 14px;
    padding-bottom: 14px;
    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__title {
        display: flex;
        align-items: center;
        h1 {
            margin: 0;
        }
    }

    &__search {
        display: flex;
        p {
            display: flex;
            align-items: center;
            margin: 0;
            white-space: nowrap;
            margin-right: 10px;
            font-size: 1rem;
        }
        input {
            margin: 0;
            max-width: 165px;
            height: 32px;
            &::placeholder {
                font-size: 12px;
            }
        }
    }

    &__buttons {
        .custom-buttom:first-child {
            margin-right: 5px;
        }
    }
    hr {
        background: #cae0e8;
        margin: 8px 0 18px;
    }

    &__main {
        
    }
}
