.transfer-modal {
    &__content {
        // position: relative;
        // margin-bottom: 62px;
        padding: 25px 20px 20px;
        width: 500px;
        // background: #26183e;

        h2 {
            width: 100%;
            border: 0;
            padding: 0;
            padding-bottom: 25px;
            font-size: 18px;
            line-height: 30px;
            color: #fff
        }

        .custom-text-input_white {
            margin-bottom: 25px;

            input {
                line-height: initial;
                margin: 25px 0 0;
            }
        }

        textarea {
            width: 100%;
            min-height: 110px;
        }
    }

    &__controls {
        margin: 30px 0 0;
        display: flex;
        justify-content: flex-end;

        .cansel-button {
            margin-right: 15px;
            color: #16C431;
            &:hover {
                color: #119B29;
            }

            &:active, &:focus {
                box-shadow: none;
            }
        }
    }
}