.event-management {
  padding: 7px 0;
  .page-header {
    display: flex;
    margin-bottom: 14px;

    // & > * {
    //     display: inline-block;
    // }
    h1 {
      margin-right: 123px;
    }
    .filters {
      position: relative;
      top: 2px;
      .radio-item {
        margin-right: 4px;
      }
      label::before {
        margin-right: 3px;
      }
    }
  }

  &__menu {
    overflow: auto;
    max-height: calc(100vh - 310px);
    .menu-item > .card {
      & > .card-header {
        background-color: #422076;
        height: 43px;
        & > *:first-child {
          // margin-left: 31px;
          padding-left: 11px;
          margin-top: 1px;
        }
        button.btn.btn-link {
          font-size: 20px;
        }
      }

      .group.accordion > .card {
        & > .card-header {
          height: 43px;
          background-color: #454ea5;
          margin-top: 4px;
          & > *:first-child {
            margin-left: 41px;
            margin-top: 3px;
          }
          button.btn.btn-link {
            font-size: 18px;
          }
        }

        .collapse .card-header {
          background-color: #636cc3;
          margin-top: 1px;
          border-radius: 0;
          border: none;
          font-size: 16px;
          padding: 5px 0;
          & > * {
            height: 33px;
            &:first-child {
              margin-left: 86px;
              padding-left: 34px;
            }
          }
        }
      }
    }
    .card {
      border: none;
      border-radius: 0;
      background-color: inherit;
      .card-header {
        margin-bottom: 0 !important;
        display: flex;
        padding-left: 0;
        & > * {
          display: flex;
          align-items: center;
          &:first-child {
            width: 30%;
          }
          // .event-management__switch {
          //     margin-left: 17%;
          // }
        }
        button.btn.btn-link {
          color: white;
          cursor: pointer;
          word-break: break-word;
          font-weight: 500;

          svg.mdi-icon {
            margin-right: 13.5px;
            transition: transform 2s;
            &.active {
              transform: rotate(180deg);
            }
          }

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  &__switch {
    margin-left: 17%;
  }
}
