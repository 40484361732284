.custom-dropdown {
  button {
    position: relative;
    width: 145px;
    height: 32px;
    background: #37133f;
    border: 0;
    color: white;
    padding-left: 12px;
    padding-right: 20px;
    text-align: start;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 0;

    &:hover {
      background: #37133f;
    }

    &:focus {
      outline: none;
      background: #481953;
      border: 0;
      box-shadow: none;
    }

    .custom-dropdown__icon {
      position: absolute;
      right: 3px;
      top: 4px;
    }

    &.btn-primary.dropdown-toggle {
      outline: none;
      background: #481953;
      border: 0;
      box-shadow: none;
      &:focus {
        box-shadow: none;
      }
      &::after {
        position: absolute;
        right: 9px;
        top: 14px;
      }
    }
  }
  a {
    // width: 145px;
    height: 34px;
    box-sizing: border-box;
    margin: 0;
    padding: 7px 20px;
    background: #ffffff;
    display: block;
    text-align: start;
    font-size: 14px;
    line-height: 20px;

    &:visited,
    &:link,
    &:active {
      color: #5f686b;
      text-decoration: none;
    }

    &.active {
      background: #ececec;
    }
  }

  .dropdown-menu.show {
    // width: 145px;
    border-radius: 0;
    padding: 0;
    max-height: 22rem;
    overflow-y: auto;
    overflow-x: hidden;
    .dropdown-item {
      // box-sizing: content-box;
    }
  }
}
