.casino-war-betting {
  display: flex;
  &__games {
    display: flex;
    margin-right: 40px;
  }
  &__games-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0 40px 0;
  }
  &__games-item-cards {
    margin: 0 7px;
    width: 32px;
    img {
      width: 32px;
      height: 44px;
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
      margin-top: 8px;
    }
  }
  .winnerCup {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: -100px;
      left: -20px;
      background-image: url(../../../static/images/Trophy.svg);
      background-position: center;
      height: 50px;
      width: 50px;
      background-repeat: no-repeat;
      background-size: 20px;
    }
  }

  &__info-column {
    min-width: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px 0 40px 0;
    &-wrapper {
      width: 100%;
    }
    &-item-board {
      min-width: max-content;
      .casino-war-betting__games-item-title {
        padding-left: 10px;
      }
      .casino-war-betting__games-item-cards {
        display: flex;
        padding-top: 10px;
        min-width: max-content;
        img {
          margin: 0 3px;
        }
      }
    }
  }
  &__info-row {
    width: 100%;
    display: flex;
    font-size: 10px;
    &-title {
      width: 30%;
      text-align: right;
      font-weight: 400;
      color: #C4C4C4;
      white-space: nowrap;
    }
    &-results {
      width: 70%;
      font-weight: 500;
      color: var(--white);
      padding-left: 4px;
    }
  }
  .casino-war-betting__games-item-title.dealer {
    color: #C4C4C4;
  }
}

@media (max-width: 1024px) {
  .casino-war-betting {
    display: flex;
    flex-direction: column;
    align-items: center;
    .casino-war-betting__games-item-title {
      font-size: 10px;
    }
    .casino-war-betting__games {
      margin-right: unset;
    }

    &__games-item-cards {
      margin: 0 3px;
      width: unset;
      img {
        width: 26px;
        height: 35px;
        filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
        margin-top: 8px;
      }
    }
    .winnerCup {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: -90px;
        left: 50%;
        transform: translate(-50%);
        background-image: url(../../../static/images/Trophy.svg);
        background-position: center;
        height: 50px;
        width: 50px;
        background-repeat: no-repeat;
        background-size: 20px;
      }
    }
    &__info-column {
      width: 100%;
      padding: 20px 0 10px 0;
      &-wrapper {
        width: 100%;
      }
    }
    &__info-row {
      width: 100%;
      display: flex;
      font-size: 10px;
      &-title {
        width: unset;
        text-align: right;
        font-weight: 500;
      }
    }
  }

}
