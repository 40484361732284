.radio-item {
    .custom-radio {
        margin-right: 0.5rem;
        position: relative;
        top: 1px;
    }

    label {
        color: white;
        height: 24px;
        position: relative;
        font-size: 14px;
    }

    &.green {
        display: inline-block;
        position: relative;
        padding: 0 6px;
        margin: 10px 0 0;
    
        input[type="radio"] {
            display: none;
    
            &:checked + label:after {
                border-radius: 11px;
                width: 8px;
                height: 8px;
                position: absolute;
                top: 6px;
                left: 4px;
                content: " ";
                display: block;
                background: #42EA5E;
            }

            &:checked + label::before {
                border: 2px solid #FFFFFF;
            }
        }
    
        label {
            
            font-weight: normal;
    
            &:before {
                content: " ";
                display: inline-block;
                position: relative;
                top: 2px;
                margin: 0 7px 0 0;
                width: 16px;
                height: 16px;
                border-radius: 11px;
                border: 2px solid #dddddd;
                box-sizing: border-box;
                background-color: transparent;
            }
        }
    }
    
}

