.breackdown-statement {
    padding-top: 13px;
    padding-bottom: 14px;
    &__header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        & > *:not(:last-child) {
            margin-right: 8px;
        }

        .custom-text-input_white,
        button:last-child {
            margin-bottom: 8px;
        }
    }

    &__title {
        display: flex;
        align-items: center;

        h1 {
            margin: 0;
        }
    }

    &__buttons {
        .custom-buttom:first-child {
            margin-right: 5px;
        }
    }

    &__main {
        .nav {
            margin-bottom: 10px;
        }

        .breackdown-statement-table {
            text-align: right;
            .text-align-left {
                text-align: left;
            }
            th {
                font-weight: bold;
                vertical-align: bottom;
            }
            td {
                font-weight: normal;
                vertical-align: middle;
            }
        }

        .market-total {
            margin: 59px 154px 0 auto;
            tr {
                td {
                    padding: 3px 9px 4px 10px;
                    &:first-child {
                        text-align: right;
                    }
                }
            }

            &__sum {
                border-top: 1px solid #fff;
                border-bottom: 1px solid #fff;
            }
        }
    }
    p {
        margin: 0;
    }
}
body .page-header.breackdown-statement__header {
    padding-bottom: 0;
}
