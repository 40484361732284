.downline-report .net-exposure-report {
    margin-top: -1px;

    & .net-exposure {
        & .page-header {
            padding: 0;
            margin-bottom: 10px;
            &__left-items {
                position: relative;
                top: 2px;
                left: -2px;
            }
        }

        &__main {

        }
    }
}
