.round-results {
  background-color: var(--round-result-bg);
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 30px 4px;
  color: var(--color-secondary-exchange);
  font-size: 10px;

  &_body {
    width: 95%;
    padding: 15px 0;
  }
  &_header {
    width: 95%;
    margin: 8px 0 15px 0;
  }
  &_header-info {
    text-align: center;
    border-bottom: 1px solid var(--color-secondary-exchange);
    padding-bottom: 10px;

    &-text {
      font-family: Roboto;
      font-weight: 500;
      font-size: 10px;
      color: #FFFFFF;
    }
  }
}

@media (min-width: 1024px) {
  .round-results {
    //min-width: 45%;
    min-width: 35%;
    background-color: var(--round-result-bg);
    border-radius: 5px;
    color: var(--color-secondary-exchange);
    font-size: 12px;

    &_body {
      width: 95%;
      padding: 15px 0;
    }
    &_header {
      width: 95%;
      margin-top: 14px;
    }
    &_header-info {
      display: flex;

      &-text {
        font-family: Roboto;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #FFFFFF;
      }
    }

    &_header-line:after {
      content: "";
      display: inline-block;
      height: 1px;
      width: 100%;
      background-color: var(--color-secondary-exchange);
    }
  }
}
