.casino-report {
  padding-top: 6px;
  .page-header {
    margin-bottom: 9px;
    padding-bottom: 1px;
    & > div > * {
      margin-bottom: 5px;
    }
    &__main,
    &__main > div,
    &__main > div > div,
    &__filters,
    &__filters > div {
      display: flex;
      align-items: center;
    }

    &__main,
    &__main > div {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__filters {
      flex-wrap: wrap;
    }

    &__main {
      h1 {
        margin-right: 144px;
        padding-top: 1px;
      }

      .date-time-range__box {
        height: 2.57rem;
      }

      .filter__toggle {
        padding: 0.6rem 8px;
        height: 2.57rem;
        margin: 0 8px;
        background-color: #37133f;
        cursor: pointer;
        .active {
          fill: #1a827d;
        }
      }
      #casino-report__filter:checked + .filter__toggle {
        background-color: #ffffff;
        color: #000000;
      }
      button.custom-buttom {
        margin-top: 3px;
      }
    }

    &__filters {
      padding-top: 1px;
      padding-bottom: 2px;
      & > *,
      & > * > * {
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
      &.invisible {
        display: none;
      }
      .dropdown-toggle.btn.btn-primary {
        background-color: #37133f;
        height: 37px;
        padding-left: 10px;
        &::after {
          top: 18px;
          right: 10px;
        }
      }
    }
  }
  &__radios {
    padding: 0 10px 0 44px;
    .radio-item {
      padding: 0 5px !important;
      margin: 9px 0 0;
    }
    label::before {
      margin: 0 3px 0 0 !important;
    }
  }

  &__alert {
    margin-bottom: 20px;
  }
  &__main {
    .main-report-content {
      margin: 0;
      max-height: calc(100vh - 350px);
    }
  }
}
