@import "../ButtonCustom/index.scss";

.daterangepicker {
  // width: 580px;
  // left: -240px !important;
  // display: grid !important;
  // grid-gap: 0;
  // grid-template-columns: repeat(2, 290px);
  // grid-template-rows: 346px 50px;
  // grid-template-areas:
  //     "fromDateTime toDateTime"
  //     "range toDateTime";
  padding: 34px 0 0;

  & > div {
    margin: 0;
  }

  .fromDateTimeContainer {
    width: 290px;
    display: grid;
    grid-gap: 4px;
    grid-template-rows: 268px 67px;
    grid-template-areas:
      "calendar"
      "time";

    grid-area: fromDateTime;
    &:not(:last-child) .fromDateHourContainer .input-group {
      left: 8px;
      .inputDate {
        border-radius: 3% 0 0 3% / 5% 0 0 5%;
      }
    }
    &:last-child {
      grid-area: toDateTime;
      .dateTimeLabel {
        color: transparent;
        position: relative;
        bottom: -90px;
        left: -288px;
      }
      .fromDateHourContainer .input-group {
        right: 8px;
        display: none;
        .inputDate {
          border-radius: 0 3% 3% 0 / 0 5% 5% 0;
        }
      }
    }

    select {
      border: none;

      // background: url(arrow_custom.svg) no-repeat right;
      // background-position-x: calc(100% - 8px);
    }

    .activeNotifier {
      display: none;
    }

    .monthYearContainer {
      justify-content: center;
      select {
        font-size: 1rem;
        color: #37133f !important;
        appearance: inherit;
      }
    }

    .calendarGrid > div {
      width: 37px;
      height: 32px;

      &:not(.calendarCell) {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        padding: 5px;
        text-transform: uppercase;
        color: #37133f !important;
      }
    }

    .buttonContainer {
      position: relative;
      justify-self: end;
      flex-direction: row-reverse;
      justify-content: space-between;
      max-width: 131px;
      width: 100%;
      // left: -292px;

      .buttonSeperator {
        border: 0;
        border-radius: 0;
        font-size: 14px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;

        &.applyButton {
          width: 65px;
          @extend .custom-buttom.cyan;
        }

        &.cancelButton {
          width: 60px;
          color: white !important;
          @extend .custom-buttom.red;
        }
      }
    }

    .calendar {
      grid-area: calendar;
    }
    .fromDateHourContainer {
      grid-area: time;
      border: 0;
      .multipleContentOnLine {
        margin: 0 10px;

        // &:nth-child(2) {
        //     // display: none;
        // }

        select {
          background-color: #eaeaea !important;
          width: 71px;
          height: 28px;
          font-size: 14px;
          padding: 4px 8px;
          &::after {
            content: "";
            border: 20px solid transparent;
            border-top: 20px solid black;
          }
        }
      }

      .dateTimeLabel {
        text-align: left;
        font-weight: bold;
        padding-bottom: 5px;
        padding-left: 15px;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        // &::after {
        //     content: "select"

        //  }
      }
      .calendarAddon.input-group-addon,
      .timeIconStyle {
        display: none;
      }
      .input-group {
        width: 48%;
        position: absolute;
        top: 8px;
        .inputDate {
          padding-left: 76px;
        }
      }
    }
    .flag {
      grid-area: flag;
    }
    .control {
      grid-area: control;
    }
  }

  .rangecontainer {
    margin-top: 20px;
    // position: absolute;
    // bottom: -100px;
    // // left: 250px;
    // left: 100px;
  }
}

#formControlsTextB {
  padding: 8px;
  width: 326px;
  height: 36px;
  span {
    float: left;
    position: relative;
    top: -1px;
  }
  .mdi-icon {
    position: relative;
    top: -1px;
    left: 5px;
  }
}

.range-button {
  z-index: 4000;
  background: #eaeaea;
  width: 116px;
  height: 28px;
}

.input-group {
  // width: 70% !important;
  &-text {
    border-radius: 4px 0 0 4px !important;
  }
}

.date-time-range__box {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  #formControlsTextB,
  .mdi-icon {
    color: white;
    border: 0;
  }

  // #DateRangePickerChildren {
  //     // padding: 0 7px;
  // }
}

#DateRangePickerContainer:focus + .test.btn {
  display: none !important;
  background-color: red;
}

@media (max-width: 1150px) {
  #DateRangePickerContainer:focus + .daterangepicker {
    display: flex !important;
  }
}


.datepickerUserCount{
  .daterangepicker{
    height: 440px;
  }
  .fromDateTimeContainer {
    width: 290px;
    display: grid;
    grid-gap: 4px;
    grid-template-rows: 268px 67px;
    grid-template-areas:
      "calendar"
      "time";

    grid-area: fromDateTime;
    .fromDateHourContainer{
      .input-group{
        width: 60%;
        position: absolute;
        top: 8px;
      }
    }
    &:not(:last-child) .fromDateHourContainer .input-group {
      left: 8px;
      .inputDate {
        border-radius: 3% 0 0 3% / 5% 0 0 5%;
      }
    }
    &:last-child {
      grid-area: toDateTime;
      visibility: hidden;
      width: 0;
      .dateTimeLabel {
        color: transparent;
        position: relative;
        bottom: -90px;
        left: -288px;
      }
      .fromDateHourContainer .input-group {
        right: 8px;
        display: none;
        .inputDate {
          border-radius: 0 3% 3% 0 / 0 5% 5% 0;
        }
      }
    }
    .buttonContainer{
      left: -275px;
      visibility: visible;
    }
  }
    // .fromDateTimeContainer{
    //   .fromDateHourContainer{
    //     .input-group{
    //       width: 60%;
    //       position: absolute;
    //       top: 8px;
    //     }
    //   }
    // }
}