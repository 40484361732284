.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.87);
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;

  &.invisible {
    display: none;
  }

  &__container {
    position: relative;
    // margin-bottom: 62px;
    padding: 0;
    background: #26183e;
    min-width: 100px;
    min-height: 100px;
    max-height: 90vh;
    max-width: 90vw;
  }

  &__close-icon {
    position: absolute;
    top: -15px;
    right: -15px;
    z-index: 1000;

    &:hover {
      cursor: pointer;
    }
  }
}
.custom-modal__container {
  max-width: 760px;
  width: 90vw;
  h3 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
  h4 {
    font-size: 1rem;
    font-weight: bold;
  }
  th,
  td {
    padding: 2px;
  }
  th {
    font-weight: bold;
    color: white;
  }
  input {
    height: 32px;
    font-weight: normal;
    color: #444444;
    & + label {
      top: -5px;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }

  .container {
    @extend .custom-modal__container;
    margin-bottom: 1.43rem;
    padding: 1.43rem 1.43rem;
  }

  &.modal-child-info {
    background: none;
    overflow-x: visible;
    overflow-y: auto;
    max-width: 800px;
    padding: 100px 20px;
    max-height: 100vh;
    margin: 0;

    // input {
    //     max-width: 150px;
    // }

    .custom-modal__close-icon {
      top: 80px;
      right: 0;
    }

    .container {
      .check {
        padding: 8px;
        label {
          position: relative;
          top: 0;
          left: 8px;
          font-weight: normal;
        }
      }
      table {
        td,
        th {
          vertical-align: middle;
          white-space: nowrap;
          padding: 8px;
        }
      }
      &.information > div:not(:last-child) {
        margin-bottom: 1.8rem;
      }
      &.credit .container__body {
        display: grid;
        grid-template-columns: minmax(250px, max-content) minmax(150px, min-content);

        > div:first-child {
          padding-right: 10px;
        }

        .credit__input:first-child {
          padding: 0 !important;
        }

        .credit__input {
          padding: 10px 0px;
          & > div {
            display: inline-block;
            & > span {
              display: block;
            }
          }

          .credit__params {
            position: relative;
            top: 15px;
            left: 2px;
          }
        }
      }
      &.notes textarea {
        min-height: 100px;
        width: 100%;
        color: black;
      }

      input:disabled {
        opacity: 0.6;
      }
    }
    .modal-child-info__controls {
      position: relative;
      bottom: 0;
      right: 0px;
      display: flex;
      justify-content: flex-end;
    }
  }

  &.modal-descendant-info {
    background: none;
    .container {
      &.information {
        th,
        td {
          text-align: left;
        }
      }

      &.bet-settings,
      &.position-taking {
        th,
        td {
          text-align: center;
        }
        td:first-child,
        th:first-child {
          text-align: left;
        }
      }
      * {
        -moz-user-select: -moz-none;
        -o-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        user-select: none;
      }
    }
    .close-button {
      position: absolute;
      bottom: -35px;
      right: 0;
      border: 2px dotted green;
    }
  }
  .close-button {
    color: #18c432;
  }
}
