.bettingPL-page {
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    button.custom-buttom {
      position: relative;
      top: 2px;
      span {
        position: relative;
        top: -2px;
        left: -1px;
      }
    }
    .page-header_search {
      display: flex;
      .custom-buttom {
        margin-left: 30px;
      }
    }
  }

  &__content {
    overflow: auto;
    max-height: calc(100vh - 475px);
    th {
      background-color: #2e1d30;
    }
    .table > thead > tr > th,
    .table > tbody > tr > td {
      padding-left: 20px;
    }
    th:nth-child(2),
    th:nth-child(3),
    th:nth-child(4),
    th:nth-child(5),
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5) {
      text-align: center;
    }
    .td_time {
      color: #909090;
    }
    thead th {
      position: sticky;
      top: 0;
    }
  }
  .bettingPL-page__radios {
    display: flex;
    .bettingPL-page__radios-content {
      .bettingPL-page__radios-content-pnl {
        text-align: center;
        margin-top: -5px;
      }
    }
  }
  .radio-item {
    padding: 0 15px !important;
    label {
      cursor: pointer !important;
    }
  }
  .content__results-wrapper {
    .market-total {
      margin-left: auto;
    }
  }
}
