.balance-report {
    background-color: #0A020B;
    padding: 10px 20px;
    .table {
        text-align: end;
        max-width: 30rem;
        tr th {
            text-align: left;
        }
        th, td {
            padding: 4px 10px;
        }
    }
}