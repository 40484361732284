.custom-text-input_black input {
    // height: 32px;
    min-width: 145px;
    background-color: #0b020c;
    color: #ffffff;
    padding: 7px 12px;
    box-sizing: border-box;
    border: 0;

    &:focus {
        outline: none;
    }
}

.custom-text-input_white {
    position: relative;
    width: 100%;
    color: black;
    input {
        margin: 20px 0 0;
        // width: 28.75rem;
        width: 100%;
        padding: 0.75rem 1rem;
        background: #ffffff;
        border: 2px solid #dddddd;
        box-sizing: border-box;

        &:focus {
            outline: none;
            border: 2px solid #cc42da;
        }

        &.warning {
            border: 2px solid #b71502;
        }

        &.success {
            border: 2px solid #11b702;
        }

        &::placeholder {
            color: #999999;
        }

        &:only-child {
            margin: 0;
        }
    }

    label {
        position: absolute;
        top: 0;
        left: 0;
        font-weight: bold;
        color: #ffffff;
    }
}
