.account-statement {
  padding-top: 7px;
  padding-bottom: 14px;

  &__header {
    margin-bottom: 10px;
  }

  .page-header {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;
      flex-wrap: wrap;
      h1 {
        margin-right: 92px;
      }
      .date-time-range__box {
        height: 36px;
        margin-top: 2px;
      }
    }
    button.custom-buttom {
      margin-top: 7px;
      span {
        position: relative;
        top: -2px;
        left: -1px;
      }
    }
  }

  &__alert {
    margin-bottom: 20px;
  }

  &__main {
    .report-tabs {
      padding: 0;
      // margin-top: 20px;
      margin-bottom: 0;
      min-height: auto;
      border: none;
      .navbar-nav a.nav-link {
        padding: 4px 10px;
        min-width: 120px;
        margin-right: 3px;
        &.active {
          background-color: #0e0310;
        }
      }
    }
    .main-page-content {
      margin: 0;
      background-color: #0e0310;
      padding: 10px;
      .tab-content {
        background-color: #0e0310;
        padding: 10px;
        max-height: calc(100vh - 370px);
        overflow: auto;
        .total-pnl {
          position: relative;
          top: -1px;
        }
        .pnl_size {
          // max-height: 38rem;
          overflow: auto;
        }

        .table {
          color: white;
        }
      }
    }
    .downline-nav {
      margin: 17px 0 21px;

      svg {
        margin: 0 3px;
      }
    }
    .breackdown-statement {
      padding-top: 7px;
      overflow: auto;
    }
  }
}
