.pnl-by-market__view-bets {
  .downline-nav {
    margin: 17px 0 21px;
    svg {
      margin: 0 3px;
    }
  }
  .breackdown-statement {
    padding-top: 7px;
    overflow: auto;
    .page-header {
      padding-bottom: 5px;
      margin-bottom: 17px;
      button {
        margin: 7px 0 0;
      }
    }
  }
}
