.main-marquee {
    &.invisible {
        display: none !important;
    }
    .marquee {
    width: 100%;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
   }
   
   .marquee span {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 15s linear infinite;
   }
   
   .marquee span:hover {
    animation-play-state: paused
   }
   
   @keyframes marquee {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
   }
} 