@mixin respond($breakpoint) {
  @if $breakpoint == Extra-small {
    @media (max-width: 576px) {
      @content;
    }
  }
  @if $breakpoint == Medium {
    @media (min-width: 768px) {
      @content;
    }
  }
  @if $breakpoint == Large {
    @media (min-width: 992px) {
      @content;
    }
  }
  @if $breakpoint == Small {
    @media (min-width: 576px) {
      @content;
    }
  }
}