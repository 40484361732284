.poker-betting {
  width: 100%;
  display: flex;
  margin-top: 20px;
  &__games {
    display: flex;
  }
  &__games-item {
    display: flex;
    flex-direction: column;
    min-width: max-content;
    margin-right: 55px;
  }
  &__games-item-title {
    padding-left: 7px;
  }
  &__games-item-cards {
    padding-top: 7px;
    img {
      width: 32px;
      height: 44px;
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
      margin-left: 6px;
    }
  }
  .winnerCup {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -15px;
      left: 45px;
      background-image: url(../../../static/images/Trophy.svg);
      background-position: center;
      height: 50px;
      width: 50px;
      background-repeat: no-repeat;
      background-size: 20px;
    }
  }

  &__info-column {
    min-width: 120px;
  }
  &__info-row {
    width: 100%;
    display: flex;
    font-size: 10px;
    &-title {
      width: 40%;
      text-align: right;
      font-weight: 400;
      color: #C4C4C4;
      white-space: nowrap;
    }
    &-results {
      width: 60%;
      font-weight: 500;
      color: var(--white);
      padding-left: 4px;
    }
  }
}

@media (max-width: 1024px) {
  div.poker-betting {
    flex-direction: column;

    .poker-betting__games {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .poker-betting__games-item {
      margin-right: 0;
    }
    .poker-betting__games-item:last-child {
      width: 100%;
      align-items: center;
      margin-top: 15px;
    }
    .poker-betting__info-column {
      margin-top: 30px;
    }
    .poker-betting__games-item-cards {
      img {
        width: 26px;
        height: 36px;
      }
      img:first-child {
        margin-left: 0;
      }
    }
    .poker-betting__info-row-title,
    .poker-betting__info-row-results {
      width: unset;
    }
    .poker-betting__games-item-title {
      padding-left: 0;
    }
    .winnerCup::before {
      background-size: 38px;
      top: 11px;
      left: unset;
      right: -10px;
      transform: translateX(100%);
    }
    .poker-betting__games-item:nth-child(2) {
      .poker-betting__games-item-title.winnerCup::before {
        left: -10px;
        transform: translateX(-100%);
      }
    }
  }
}
