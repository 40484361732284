.cards-teenpatti-betting {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  &__games {
    display: flex;
  }
  &__games-item {
    display: flex;
    flex-direction: column;
    min-width: max-content;
    margin-right: 55px;
  }
  &__games-item-title {
    padding-left: 7px;
  }
  &__games-item-cards {
    padding-top: 7px;
    img {
      width: 32px;
      height: 44px;
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
      margin-left: 6px;
    }
  }
  .winnerCup {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -18px;
      right: -15px;
      background-image: url(../../../static/images/Trophy.svg);
      background-position: center;
      height: 50px;
      width: 50px;
      background-repeat: no-repeat;
      background-size: 18px;
    }
  }
  &__info-column {
    min-width: 210px;
  }
  &__info-row {
    width: 100%;
    display: flex;
    font-size: 10px;
    &-title {
      width: 40%;
      text-align: right;
      font-weight: 400;
      color: #C4C4C4;
      white-space: nowrap;
    }
    &-results {
      width: 60%;
      font-weight: 500;
      color: #FFFFFF;
      padding-left: 4px;
    }
  }
}

@media (max-width: 1024px) {
  .cards-teenpatti-betting {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-top: -10px;
    margin-bottom: 5px;
    &__games {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
    &__games-item {
      display: flex;
      flex-direction: column;
      min-width: max-content;
      margin-right: 10px;
    }

    &__games-item-cards {
      display: flex;
      justify-content: center;

      img {
        width: 26px;
        height: 35px;
        filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
        margin-left: 6px;
      }
    }
    &__info-row {
      padding: 2px 0;
    }
    .winnerCup {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        background-position: center;
        background-image: url(../../../static/images/Trophy.svg);
        height: 50px;
        width: 50px;
        background-repeat: no-repeat;
        background-size: 35px;
        right: -50px;
        left: unset;
        top: 10px;
      }
    }
    .second.winnerCup:before {
      right: unset;
      left: -45px;
    }
  }

  .cards-teenpatti-betting__info-column {
    padding-top: 38px;
  }
  .cards-teenpatti-betting__info-row-title {
    width: unset;
  }
}
