.header-dropdown {
    &__toggle.dropdown-toggle {
        padding: 0;
        font-size: 14px;
        &.hover-opacity:hover {
            cursor: pointer;
            opacity: 0.6;
        }

        &.triangle_disabled::after {
            display: none;
        }
    }

    &__toggle,
    &__toggle:active,
    &__toggle:focus {
        background: none !important;
        border: none !important;
        box-shadow: none !important;
    }
    span {
        
        position: relative;
        top: 1px;
    }

    &__menu.dropdown-menu {
        font-size: 14px;
        border-radius: 0;
        padding: 4px 6px;
        border: 1px solid #dddddd;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

        .dropdown-item {
            padding: 10px;
        }

        &::before {
            content: "";
            height: 0;
            position: absolute;
            bottom: 100%;
            right: 50%;
            border-style: solid;
            margin-right: -7px;
            border-width: 0 8px 8px 8px;
            border-color: #ffffff transparent;
        }
    }
}
