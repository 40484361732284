.net-exposure {
  height: inherit;
  // overflow: auto;
  .page-header {
    padding-top: 7px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    & div:not(.refresh) {
      display: flex;
      flex-wrap: wrap;
    }
    h1 {
      margin-right: 20px;
    }
    .group-radios {
      margin-right: 3px;
      .radio-item.blue {
        align-items: center;
        margin-right: 16px;
        margin-left: 2px;
        label {
          font-weight: 400;
          margin: 0;
          margin-top: 6px;
        }
      }
    }
    .sport-radios {
      &__label {
        font-size: 12px;
        display: inline-block;
        margin: 15px 10px 0 0;
        color: #939;
      }
      .radio-item.green {
        position: relative;
        top: 2px;
        margin-right: 4px;
      }
    }
    .refresh {
      padding-top: 7px;
      display: flex;
      &__timer {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 4px;
        left: -3px;
        width: 20px;
        height: 20px;
        margin: 0 5px 0 20px;
        color: rgba(202, 224, 232, 0.8);
      }
      &__button {
        width: 6.62rem;
      }
    }
  }

  &__main {
    max-height: 70vh;
    overflow-y: auto;
    table {
      thead {
        tr {
          th:first-child {
            width: calc(100vw / 4);
          }
        }
      }
      th,
      td {
        padding: 4px 8px !important;
        font-size: 13px;
        vertical-align: middle !important;
      }
      .event-title {
        background: linear-gradient(to bottom, #44227f 84%, rgba(68, 34, 127, 0.56) 100%);
      }
      .market {
        display: table-cell;
        &_name {
          float: left;
        }
        &__label {
          display: inline-block;
          padding-left: 10px;
          margin-right: 20px;
          text-align: right;
          width: 100%;
          white-space: nowrap;
        }
        &__container-div {
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;
          white-space: nowrap;
          div {
            &:first-child {
              margin-right: 15px; //30
            }
            &:last-child {
             overflow: hidden;
            }
          }
        }
      }
      .blue-back {
        background-color: #5059a7;
        span {
          white-space: nowrap;

        }
      }
      .td-buttons {
        vertical-align: middle;
        padding: 0 !important;
        & > div {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 28px;
            padding: 4px 8px;
            background-color: #485096;
            & > * {
              color: #2ce5dd;
              &:hover {
                cursor: pointer;
                color: #7ceee8;
              }
            }
          }
        }
      }
      .stake,
      .under-no {
        background-color: #deeaf7;
        color: #333333;
        font-weight: 700;
        span {
          color: #333333;
        }
      }
      .over-yes {
        background-color: #e6f2ff;
        color: #333333;
        font-weight: 700;
        span {
          color: #333333;
        }
      }
    }
  }
}
