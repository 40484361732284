.race-betting {
  min-width: 540px;
  display: flex;
  margin-top: -10px;
  &__games {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }
  &__games-item {
    display: flex;
    min-width: max-content;
  }
  &__games-item-img {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 25px;
      height: 25px;
    }
  }
  &__games-item-cards {
    margin: 5px 0;
    img {
      width: 32px;
      height: 44px;
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
      margin: 0 3px;
    }
  }
  &__games-item-winner {
    position: absolute;
    right: -75px;
    opacity: 0;
  }
  &__winner {
    width: 23px;
    height: 210px;
    position: absolute;
    right: -20px;
    top: 0;
    &-text {
      font-family: Times New Roman;
      writing-mode: vertical-lr;
      text-orientation: upright;
      letter-spacing: 10px;
      font-weight: 400;
      font-size: 24px;
      line-height: 35px;
      text-align: center;
      //color: #E5A24A;
      color: var(--open-bets-buble-bg);
    }
  }
  .winnerCup {
    position: absolute;
    opacity: 1;
    &::before {
      content: "";
      position: absolute;
      top: -5px;
      left: 25px;
      background-image: url(../../../static/images/Trophy.svg);
      background-position: center;
      height: 50px;
      width: 50px;
      background-repeat: no-repeat;
      background-size: 20px;
    }
  }
  &__info-column {
    min-width: 120px;
    margin-left: 150px;
  }
  &__info-row {
    width: 100%;
    display: flex;
    font-size: 10px;
    align-items: center;
    &-title {
      width: 30%;
      text-align: right;
      font-weight: 400;
      color:  #C4C4C4;
      white-space: nowrap;
    }
    &-results {
      width: 70%;
      font-weight: 500;
      color: #FFFFFF;
      padding-left: 4px;
    }
  }
}


@media (max-width: 1024px) {
  .race-betting {
    min-width: unset;
    display: flex;
    margin-top: -10px;
    flex-direction: column;
    width: 70%;
    &__games {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      margin-top: -10px;
    }
    &__games-item-cards {
      display: flex;
      margin: 3px 0;
      img {
        width: 26px;
        height: 35px;
        filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
        margin-left: 5px;
      }
    }
    &__info-row {
      padding: 2px 0;
    }
  }
  .race-betting__games-item-img img {
    width: 20px;
    height: 20px;
  }
  .race-betting__winner-text {
    letter-spacing: 8px;
    font-size: 22px;
  }
  .race-betting__games-item-cards.winnerCup:before {
    background-size: 35px;
    top: -9px;
  }
  .race-betting__info-column {
    padding-top: 25px;
    margin-left: unset;
  }
  .race-betting__info-row-title {
    width: unset;
  }
}
