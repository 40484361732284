.cards32-casino-betting {
  width: 100%;
  display: flex;
  min-width: 570px;
  &__games {
    display: flex;
    margin: 0 30px 0 0;
    &-item-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 80px;
    }
    &-item {
      display: flex;
      flex-direction: column;
      min-width: max-content;
      margin-right: 30px;
      &-cards {
        padding: 10px 0;
        img {
          width: 32px;
          height: 44px;
          filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
          margin-left: 7px;
        }
        img.winner {
          width: 20px;
          height: 20px;
          transform: translate(3px, -50%);
        }
      }
      &-title {
        padding-left: 6px;
        margin: 0;
        span {
          color: #C4C4C4;
        }
      }
    }
    .cards32-casino-betting__games-item-cup {
      width: 20px;
      height: 20px;
    }
    .img.winner {
      width: 15px;
      height: 15px;
    }
  }

  &__info-column {
    min-width: 100px;
    padding-top: 20px;
  }
  &__info-row {
    width: 100%;
    display: flex;
    font-size: 10px;
    &-title {
      width: 45%;
      text-align: right;
      font-weight: 400;
      color:  #C4C4C4;
      white-space: nowrap;
    }
    &-results {
      width: 55%;
      font-weight: 500;
      color: #FFFFFF;
      padding-left: 4px;
    }
  }
}


@media (max-width: 1024px) {
  .cards32-casino-betting {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-top: -10px;
    margin-bottom: 5px;
    min-width: unset;
    &__games {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
    &__games-item {
      //display: flex;
      //flex-direction: column;
      //min-width: max-content;
      //margin-right: 10px;
    }

    &__games-item-cards {
      display: flex;

      img {
        width: 26px;
        height: 35px;
        filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
        margin-left: 6px;
      }
      img.winner {
        width: 35px;
        height: 35px;
        transform: unset;
      }
    }
    &__info-row {
      padding: 2px 0;
    }
  }

  .cards32-casino-betting__info-column {
    padding-top: 30px;
  }
  .cards32-casino-betting__info-row-title {
    width: unset;
  }
}
