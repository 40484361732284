@import "../../scss/media.scss";

.modal-rules {
    max-width: 1024px;
    width: 100%;
    margin-bottom: 0;

    @include respond(Extra-small) {
        font-size: 10px;
        h1 {
            font-size: 20px;
        }
        h2 {
            font-size: 16px;
        }
        h3 {
            font-size: 13px;
        }
    }
    @include respond(Small) {
        font-size: 11px;
        h1 {
            font-size: 22px;
        }
        h2 {
            font-size: 18px;
        }
        h3 {
            font-size: 14px;
        }
    }
    @include respond(Medium) {
        font-size: 13px;
        h1 {
            font-size: 24px;
        }
        h2 {
            font-size: 20px;
        }
        h3 {
            font-size: 16px;
        }
    }
    @include respond(Large) {
        font-size: 15px;
        h1 {
            font-size: 28px;
        }
        h2 {
            font-size: 24px;
        }
        h3 {
            font-size: 18px;
        }
    }

    &__content {
        margin: 2rem 1.25rem;
        overflow: auto;
        height: 85vh;
        h1 {
            font-weight: normal;
            text-align: center;
        }

        h2,
        h3 {
            font-weight: bold;
        }

        h1,
        h2 {
            text-transform: uppercase;
            margin-bottom: 2rem;
        }

        h3,
        p {
            margin-bottom: 1rem;
        }

        p {
            margin-left: 2.1rem;
        }

        i {
            color: #aaaaaa;
        }

        ul {
            padding-left: 4rem;
        }
    }
}
