.poker-20-20-betting {
  width: 100%;
  display: flex;
  margin-top: 25px;
  &__games {
    display: flex;
  }
  &__games-item {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    width: max-content;
  }
  &__games-item-title {
    padding-left: 5px;
  }
  &__games-item-cards {
    padding-top: 7px;
    display: flex;
    img {
      width: 32px;
      height: 44px;
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
      margin-left: 6px;
    }
  }

  &__info-column {
    min-width: 120px;
  }
  &__info-row {
    width: 100%;
    display: flex;
    font-size: 10px;
    &-title {
      //width: 30%;
      text-align: right;
      font-weight: 400;
      color: #C4C4C4;
    }
    &-results {
      //width: 70%;
      font-weight: 500;
      color: var(--white);
      padding-left: 4px;
    }
  }

  .winnerCup {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -16px;
      left: 38px;
      background-image: url(../../../static/images/Trophy.svg);
      background-position: center;
      height: 50px;
      width: 50px;
      background-repeat: no-repeat;
      background-size: 20px;
    }
  }
}

@media (max-width: 1280px) {
  div.poker-20-20-betting {
    flex-direction: column;

    .poker-20-20-betting__games {
      margin-bottom: 30px;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .poker-20-20-betting__games-item-cards{
      img {
        width: 26px;
        height: 36px;
      }
      img:first-child {
        margin: 0;
      }
    }
    .poker-20-20-betting__games-item {
      margin-right: 0;
      min-width: max-content;
    }

    .poker-20-20-betting__games-item:last-child {
      margin-top: 15px;
      align-items: center;
      width: 100%;
    }
    .poker-20-20-betting__games-item:nth-child(1) {
      .winnerCup:before {
        height: 35px;
        width: 28px;
        left: unset;
        top: 23px;
        right: -35px;
        background-size: 33px;
      }
    }
    .poker-20-20-betting__games-item:nth-child(2) {
      .winnerCup:before {
        height: 35px;
        width: 28px;
        top: 23px;
        left: -35px;
        background-size: 33px;
      }
    }
  }
}

