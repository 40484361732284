@import "../../scss/variables.scss";
@import "../../scss/media.scss";

.sidebar-menu {
  width: 14.29rem;
  height: calc(100vh - 85px);
  display: flex;
  justify-content: center;
  position: fixed;
  background-color: rgba(39, 8, 42, 1);

  transition: 350ms;
  z-index: 1;
  @include respond(Extra-small) {
    left: -12.4rem;
  }
  @include respond(Small) {
    left: -12.7rem;
  }
  @include respond(Medium) {
    left: -12.9rem;
  }
  // @include respond(Large) {
  //   font-size: 14px;
  // }

  .show-collapse {
    flex: 1 1 auto;
    min-height: 1px;

    a {
      cursor: pointer;
      color: white !important;
      display: flex;
      height: 2.86rem;
      background-color: $color-dark-violet;
      padding-left: 1.43rem;
      align-items: center;
    }
    a.active {
      background-color: rgba(49, 31, 51, 1);
    }
    a:hover {
      text-decoration: none;
      background-color: rgba(255, 255, 255, 0.2);
      color: #c2c1c2;
    }
  }

  .collapse {
    background-color: $color-dark-violet;
  }

  .accordion-content {
    flex: 1 1 auto;
    min-height: 1px;
  }

  .accordion {
    flex-grow: 1;
    background-color: rgba(26, 5, 28, 1);
    width: 100%;
    height: calc(100% - 10px);
  }

  .toggle-show {
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    background-color: rgba(147, 132, 149, 1);
    transition: 0.3s;
    z-index: 2;

    &__background {
      width: 20px;
      background-color: rgba(58, 12, 63, 1);
      position: absolute;
      left: calc(14.29rem - 20px);
      bottom: 0;
      z-index: 1;
      height: 10px;
    }
  }

  .toggle-show.active-all-time {
    opacity: 1;
    cursor: pointer;

    svg {
      transform: rotate(180deg);
    }
  }

  .toggle-button {
    height: 2.86rem;
    box-sizing: content-box;
    // width: calc(100% - 20px);
    display: flex;
    color: white;
    justify-content: space-between;
    align-items: center;
    border: none;
    padding-left: 0.75rem;
    background-color: #3c2b3e;
    cursor: pointer;
    
    svg {
      width: 20px;
      height: 20px;
      right: 4px;
      position: relative;
    }
  }

  .toggle-button:hover {
    background-color: rgba(49, 30, 52, 1);
    color: #c2c1c2;
  }

  .toggle-button.active {
    svg {
      transform: rotate(180deg);
      transition: all 0.5s ease;
    }
  }
}

.sidebar-menu:hover .toggle-show {
  opacity: 1;
  cursor: pointer;
}

.sidebar-menu.active {
  left: 0;
  transition: 350ms;
}
