.main-wrapper {
  background-color: #3a0c3f;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .middle {
    margin-top: 85px;
    height: 100%;

    &__background {
      width: 100%;
      background-color: #27082a;
      height: calc(100% - 90px);
      position: fixed;
      z-index: 0;
    }

    .sidebar-menu:not(.active) + .page-content {
      margin-left: 20px;
    }
    .page-content {
      margin-left: 14.29rem;
      transition: 350ms;
      z-index: 1;
      height: 100%;
      position: relative;

      .main-marquee {
        background: #1a051c;
        display: flex;
        align-items: center;
        height: 2.15rem;
        margin-bottom: 15px;
      }

      .main-content {
        background: #180519;
        margin-top: 10px;
        padding: 0 20px;
        // padding-bottom: 20px;
      }
    }
  }
}
