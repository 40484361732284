.notifications-page {
    // padding-top: 14px;
    // padding-bottom: 20px;
    padding: 10px 0;

    textarea {
        width: 100%;
        height: 84px;
        min-height: 48px;
    }

    &__button {
        display: flex;
        justify-content: flex-end;
    }
}