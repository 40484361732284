.pnl-report {
  .date-nav {
    display: flex;
    justify-content: space-between;
    p {
      margin: 0;
    }
  }

  .main-report-content {
    padding: 0;
    max-height: calc(100vh - 320px);
    overflow: auto;
  }

  .table {
    thead tr {
      text-align: end;
      th {
        padding: 5px;
      }
      &:first-child th {
        text-align: center !important;
      }
    }

    tbody {
      td {
        text-align: end;
        vertical-align: middle;
        &.event-name {
          text-align: start;
          padding-left: 16px;
          &.recolor {
            background-color: #6b5f6c;
            &:hover {
              background-color: #968c97c5 !important;
            }
          }
        }

        &.market-name {
          text-align: start;
          padding-left: 24px;
        }
      }
      th {
        text-align: end;
      }
      .delimiter-row {
        background-color: #706572;
        &:hover {
          background-color: #aa9eacc5 !important;
        }
        .delimiter-td {
          text-align: start;
        }
      }
    }
    .border_right-side {
      border-right: 1px solid #ddd !important;
    }
  }
}
