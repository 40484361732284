.deposit-entry-pop-up {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  &__form-wrapper {
    padding: 25px 20px;
    background: #26183E;
    width: 500px;
    height: 310px;
    .deposit-entry__label {
      padding: 0 3px;
    }
  }
  &__form-title {
    font-size: 18px;
    margin-bottom: 30px;
  }
  &__title_row {
    display: flex;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 25px;
    &-id {
      margin-left: 45px;
      font-weight: 700;
    }
  }
  &__form-input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  &__form-input-wrapper:not(:last-child) {
    margin-bottom: 25px;
  }
  &__form-input {
    border-radius: 0;
    height: 42px;
    font-weight: 400;
    font-size: 14px;
    color: #5F686B;
    padding: 12px 15px;
  }
  &__form-input::-webkit-input-placeholder {
    font-weight: 400;
    font-size: 14px;
    color: #5F686B;
  }
  &__form-input::-moz-placeholder {
    font-weight: 400;
    font-size: 14px;
    color: #5F686B;
  }
  &__form-button {
    width: 65px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
  &__form-button:hover {
    background: #109993;
    box-shadow: 2px 2px 0px #073e3c, inset -2px -2px 0px #095551;
  }
  &__form-reset {
    margin-right: 25px;
    background: transparent;
    color: #16C431;
  }
  &__form-save {
    background: #0F8422;
    box-shadow: inset -2px -2px 0px #0B5617;
  }

  &__form-footer {
    display: flex;
    justify-content: flex-end;
  }
}
