.search-custom-main {
    position: relative;
    width: 100%;
    input {
        width: 100%;
        height: 36px;
        border: none;
        border-radius: 24px;
        background: #2F2F2F;
        padding: 0 35px;
        position: relative;
    }
}
.search-custom-main:after {
    content: '';
    background-image: url("../../static/images/search.svg");
    width: 20px;
    height: 15px;
    position: absolute;
    left: 14px;
    top: 12px;
    background-repeat: no-repeat;
    background-size: contain;
}
