.new-ma {
  padding: 21px 1px;
  height: 100%;

  .input-with-params .custom-text-input_white {
    margin-bottom: 0;
  }

  &__containers {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    max-height: 95%;
  }

  &__container {
    padding: 1.79rem 1.43rem;
    background-color: #28162a;
    margin: 0 1.43rem 1.43rem 0;
    height: fit-content;

    h2 {
      font-size: 1.29rem;
      margin: 0;
    }
    hr {
      background: #ffffff;
      margin: 0.35rem 0 0.72rem 0;
    }
    h3 {
      font-size: 1rem;
      font-weight: bold;
    }

    .custom-text-input_white {
      max-width: 12.25rem;
      margin: 1.75rem 0;
      input {
        height: 2.29rem;
      }
      label {
        top: -5px;
      }
      &.text-input_small {
        width: 2.5rem;
        display: inline-block;
        margin: 6px;
        input {
          text-align: center;
          padding: 0.75rem 0;
          margin: 0;
        }
      }
      &:only-child {
        margin-top: 15px;
      }
    }

    .radios-container {
      display: flex;
      justify-content: space-between;
      .radio-item.green {
        padding: 0;
        label {
          margin-bottom: 0;
          &:before {
            margin-right: 5px;
          }
        }
      }
    }
  }
  .new-ma__information__copy-button {
    width: 172px;
    height: 32px;
    background: #3C8132;
    box-shadow: inset -2px -2px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
    }
  }
  .new-ma__information__copy-button:hover {
    transform: translate(-1px, -1px);
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.25);
    background: #408b36;
  }
  .new-ma__information__copy-button:active {
    transform: translate(1px, 1px);
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.25);
  }
  .new-ma__information__pass-input-wrapper {
    position: relative;
    .custom-text-input_white {
      pointer-events: none;
    }
    .new-ma__information__pass-generate {
      width: 32px;
      height: 32px;
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(-40%, 60%);
      z-index: 1;
      cursor: pointer;
      transition: all 0.5s;
    }
    .new-ma__information__pass-generate:after {
      content: "";
      background-color: #3C8132;
      mask-image: url("../../../static/images/reload-pass.svg");
      mask-repeat: no-repeat;
      mask-size: 15px;
      mask-position: center;
      width: 32px;
      height: 32px;
      position: absolute;
    }
    .new-ma__information__pass-generate.active:after {
      animation: circle 0.4s;
    }
    @keyframes circle{
      0%{
        transform: rotate(0deg);
        //mask-size: 16px;
      }
      60%{
        transform: rotate(360deg);
        //mask-size: 8px;
      }
      100%{
        transform: rotate(360deg);
        //mask-size: 16px;
      }
    }
  }

  &__notes {
    margin-bottom: 0;
    width: max-content;

    textarea {
      color: black;
      resize: both;
      width: 270px;
      height: 100px;
      min-width: 170px;
      min-height: 75px;
      &:focus {
        outline: none;
        border: 2px solid #cc42da;
      }
    }
  }

  &__controls {
    position: absolute;
    bottom: 25px;
    right: 20px;
  }

  &__position-taking {
    .custom-text-input_white {
      margin-bottom: 0;
    }
    .input-with-params > .params {
      top: 0;
    }
    p {
      font-weight: bold;
      margin: 44px 15px 0;
    }
  }
}
