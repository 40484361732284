.balance-tracker {
    background-color: #1a051c;
    margin-top: 5px;
    padding: 6px 20px;

    .status-list > div > div,
    label {
        white-space: nowrap;
    }

    .status-list {
        flex-wrap: wrap;
        flex-direction: row;

        @media (max-width: 1280px) {
            flex-direction: column;
        }

        & > div {
            padding-top: 3px;

            & > div {
                display: inline-block;
                width: fit-content;
                @media (max-width: 799px) {
                    display: block;
                }
            }
        }
    }

    dd,
    label,
    .status-list {
        margin: 0;
    }

    dd {
        margin-right: 20px;
    }

    dt {
        margin-right: 3px;
    }

    dd,
    dt {
        display: inline-block;
    }

    &__content {
        color: white;
        font-size: 14px;
        display: flex;
        justify-content: space-between;

        .status-list,
        .agency-settings {
            display: flex;
            justify-content: space-between;
        }

        .agency-settings {
            position: relative;
            align-items: center;

            &__label {
                font-weight: 400;
            }

            @media (min-width: 400px) {
                &__label {
                    margin-right: 0;
                }
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            @media (min-width: 992px) {
                &__label {
                    margin-right: 9px;
                }
                flex-direction: row;
                flex-wrap: nowrap;
            }
        }
    }

    @media (max-width: 520px) {
        &__content {
            flex-wrap: wrap;
        }
    }
}
