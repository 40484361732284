.queen-betting {
  width: 100%;
  //max-width: 495px;
  display: flex;
  justify-content: space-between;
  margin-right: 120px;
  &__games {
    display: grid;
    grid-template-rows: 1fr 1fr;
    margin: 5px 0px 0 0;
    &-item-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 80px;
    }
    &-item {
      display: flex;
      flex-direction: column;
      min-width: max-content;
      position: relative;
      &-cards {
        padding: 15px 0;
        img {
          width: 32px;
          height: 44px;
          filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
          margin-left: 7px;
        }
      }
      &-title {
        padding-left: 6px;
        margin: 0;
        width: 10px;
        white-space: nowrap;
        span {
          color: #C4C4C4;
        }
      }
    }
    .queen-betting__games-item-cup {
      position: absolute;
      top: 45px;
      width: 20px;
      height: 20px;
      right: -25px;
    }
  }

  &__info-column {
    min-width: 100px;
  }
  &__info-row {
    width: 100%;
    display: flex;
    font-size: 10px;
    &-title {
      width: 60%;
      text-align: right;
      font-weight: 400;
      color:  #C4C4C4;
      white-space: nowrap;
    }
    &-results {
      width: 40%;
      font-weight: 500;
      color: var(--white);
      padding-left: 4px;
    }
  }
}

@media (max-width: 1024px) {
  div.queen-betting {
    margin: 0;
    flex-direction: column;

    .queen-betting__info-column {
      margin-top: 30px;
    }
    .queen-betting__info-row-title,
    .queen-betting__info-row-results {
      width: unset;
    }
    .queen-betting__games-item-row {
      column-gap:unset;
      display: flex;
      justify-content: space-between;
    }
    .queen-betting__games-item-cards {
      img {
        width: 26px;
        height: 36px;
      }
      img:first-child {
        margin-left: 0;
      }
    }
    .queen-betting__games-item-title {
      padding-left: 0;
      width: unset;
    }
    .queen-betting__games-item-cup {
      height: 44px;
      width: 33px;
      right: unset;
      top: 55%;
      left: -10px;
      transform: translate(-100%, -50%);
    }
    .queen-betting__games-item-cup.rightCup {
      height: 44px;
      width: 33px;
      left: unset;
      top: 55%;
      right: -10px;
      transform: translate(100%, -50%);
    }
  }
}
