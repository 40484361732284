$color-light-violet: rgba(39, 8, 42, 1);
$color-dark-violet: rgba(23, 5, 25, 1);
$color-background-violet: rgba(58, 12, 63, 1);

.-positive {
  color: #3cd247 !important;
  font-weight: bold !important;
}

.-negative {
  color: #f43 !important;
  font-weight: bold !important;
}
