.switch {
    position: relative;
    display: inline-block;
}
.switch-input {
    display: none;
}
.switch__label {
    margin: 0;
    display: block;
    width: 73px;
    height: 28px;
    // width: 4.55rem;
    // height: 1.75rem;
    text-indent: -150%;
    clip: rect(0 0 0 0);
    color: transparent;
    user-select: none;
}
.switch__label::before,
.switch__label::after {
    content: "";
    display: block;
    position: absolute;
    cursor: pointer;
}
.switch__label::before {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 9999em;
    -webkit-transition: background-color 0.25s ease;
    transition: background-color 0.25s ease;
}

.label_red::before {
    background-color: #ff0000;
    background-image:url('../../static/images/off_white.svg');
    
}

.label_white::before {
    background-color: white;
    border: 1px solid #E6E6E6;
    background-image:url('../../static/images/off_gray.svg');
}

.switch__label::after {
    top: 0;
    left: -1px;
    width: 28px;
    height: 28px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 80px;    
    -webkit-transition: left 0.25s ease;
    transition: left 0.25s ease;
}
.switch-input:checked + .switch__label::before {
    background-color: #00d85f;
    border: none;
    background-image:url('../../static/images/on_white.svg');
}
.switch-input:checked + .switch__label::after {
    left: 46px;
}
