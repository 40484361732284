@import "./media.scss";

html,
body {
  background-color: #3a0c3f;
  font-family: Lato, Avenir, Verdana, Nunito;
  color: white;
  .hover-opacity:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  .hide {
    display: none !important;
  }
  .center-text {
    text-align: center !important;
  }

  .transform-rotate {
    transform: rotate(180deg);
  }

  .min-width-100px {
    min-width: 100px;
  }
  
  .justify-content-start {
    justify-content: start !important;
  }

  *:not(.dropdown-menu):not(.dropdown-menu__container) {
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 8px;
      height: 8px;
      // width: 16px;
      // height: 16px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(146, 132, 147, 0.5);
      // background-color: #dce3e8;
      border-radius: 16px;
      // border: 5px solid #37133f;
      min-height: 50px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 10px;
    }
  }

  .input-with-params {
    & > div {
      display: inline-block;
      & > span {
        display: block;
      }
    }

    .params {
      position: relative;
      top: 15px;
      left: 2px;
    }
  }

  .custom-checkbox[type="checkbox"] {
    display: inline-block;
    height: 16px !important;
    width: 16px !important;
    border: 2px solid #ddd;
    overflow: hidden;
    margin-top: -4px;
    vertical-align: middle;
    -webkit-appearance: none;
    outline: 0;
    background: transparent;

    &:focus {
      outline: none;
    }

    &:checked:before {
      content: "\2713";
      font-size: 21px;
      -webkit-font-smoothing: antialiased;
      text-align: center;
      line-height: 12px;
      color: #42ea5e;
      display: block;
      left: -2px;
      position: relative;
    }
  }

  .table {
    background: transparent;
    td,
    th {
      border: 0 !important;
    }
    &.table-dark.table-striped tbody tr {
      &:nth-of-type(odd) {
        background-color: rgba(245, 245, 245, 0.1);
      }
      &:hover {
        background-color: #ffffff26;
      }
    }
  }

  hr {
    height: 1px;
    background: white;
    border: none;
  }

  h1 {
    display: flex;
    align-items: center;
    color: #cae0e8;
    font-size: 24px;
    height: 42px;
    margin-bottom: 0;
  }

  textarea {
    color: black;
    outline: none;
    border: 2px solid #dddddd;
    padding: 4px 6px;
    -webkit-transition: linear 0.2s;
    transition: linear 0.2s;
    color: #666666;
    &:focus {
      border-color: #a222ad;
      color: black;
    }
  }

  input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .page-header {
    margin-top: 0;
    padding-bottom: 5px;
  }

  a,
  a.no-href {
    font-weight: normal;
    line-height: 20px;
    color: #2ae5dd;
    &:hover {
      color: #7ceee8;
      cursor: pointer;
      text-decoration: none;
    }
  }

  @include respond(Extra-small) {
    font-size: 10px;
  }
  @include respond(Small) {
    font-size: 12px;
  }
  @include respond(Medium) {
    font-size: 14px;
  }
  @include respond(Large) {
    font-size: 14px;
  }
}
