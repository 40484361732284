.downline-report {
  padding-top: 10px;
  padding-bottom: 14px;

  &__header {
    margin-bottom: 9px;
  }

  &__delimiter {
    background-color: #27082a;
    height: 10px;
    position: relative;
    left: -20px;
    width: calc(100% + 40px);
  }

  &__main {
    h1 {
      display: none !important;
    }
    .report-tabs {
      padding: 0;
      margin-top: 25px;
      margin-bottom: 0;
      min-height: auto;
      border: none;
      .navbar-nav a.nav-link {
        padding: 10px 20px;
        color: white;
        &.active {
          background-color: #0e0310;
        }
      }
    }
    .main-page-content {
      margin: 0;
      background-color: #0e0310;
      padding: 10px;
      .tab-content {
        background-color: #0e0310;
        padding: 10px;
        padding-top: 11px;
      }
    }

    .account-statement {
      padding-top: 3px;
      .page-header {
        padding-bottom: 0;
      }

      &__main .report-tabs {
        margin-top: 5px;
      }
      .main-page-content,
      .main-page-content > .tab-content,
      .navbar-nav a.nav-link.active {
        background-color: #0a020b !important;
      }
      .navbar-nav a.nav-link {
        padding: 4px 10px !important;
      }
      .total-pnl {
        position: relative;
        top: -1px;
      }
    }
    .transfer-statement-page {
      .page-header {
        justify-content: flex-end;
      }
      &__content {
        max-height: 35rem;
        height: fit-content;
      }
    }
  }
  // .account-statement__main .main-page-content .tab-content {
  //     max-height: 33rem;
  // }
  .account-statement__main .main-page-content .tab-content .pnl_size {
    max-height: 28rem;
    overflow: auto;
  }
}
