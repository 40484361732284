.casino-meter-betting {
  width: 100%;
  &__item-wrapper {
    display: flex;
    margin: 30px 0;
    justify-content: space-between;
    .casino-meter-betting__games-item:last-child {
      margin-left: 300px;
      transform: translate(-100%);
    }
  }
  &__games-item {
    display: flex;
    min-width: max-content;
    align-items: center;
    &-title {
      width: 85px;
      margin: 0;
      white-space: nowrap;
    }
  }
  &__games-item-cards {
    display: flex;
    img {
      width: 32px;
      height: 44px;
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
      margin-left: 6px;
    }
  }
}


@media (max-width: 1024px) {
  .casino-meter-betting {
    width: 100%;
    &__item-wrapper {
      display: flex;
      margin: 20px 0 30px 0;
      justify-content: space-between;
      .casino-meter-betting__games-item:last-child {
        margin-left: unset;
        transform: unset;
        padding: unset;
        justify-content: flex-end;
      }
    }
    &__games-item {
      display: flex;
      min-width: max-content;
      align-items: flex-start;
      flex-direction: column;
      &-title {
        font-size: 10px;
        font-weight: 500;
        padding: 0 0 12px 5px;
        span:last-child {
          display: none;
        }
      }
    }
    &__games-item-cards {
      display: flex;
      img {
        width: 26px;
        height: 35px;
        filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
        margin-left: 5px;
      }
    }
  }
}
