.voidInvalid-report {

  .mdi-icon.active{
    color: #10827d;
  }
  .page-header {
    margin-bottom: 9px;
    padding-bottom: 1px;
    & > div > * {
      margin-bottom: 5px;
    }
    &__main,
    &__main > div,
    &__main > div > div,
    &__filters,
    &__filters > div {
      display: flex;
      align-items: center;
    }

    &__main,
    &__main > div {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__filters {
      flex-wrap: wrap;
    }

    &__main {
      .date-time-range__box {
        height: 2.57rem;
      }

      .filter__toggle {
        padding: 0.6rem 8px;
        height: 2.57rem;
        margin: 0 8px;
        background-color: #37133f;
        cursor: pointer;
      }
      #voidInvalid-report__filter:checked + .filter__toggle {
        background-color: #ffffff;
        color: #000000;
      }
    }

    &__filters {
      padding-top: 1px;
      padding-bottom: 2px;
      & > *,
      & > * > * {
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
      &.invisible {
        display: none;
      }
      .dropdown-toggle.btn.btn-primary {
        background-color: #37133f;
        height: 37px;
        padding-left: 10px;
        &::after {
          top: 18px;
          right: 10px;
        }
      }
    }
  }
  &__radios {
    padding: 0 10px 0 44px;
    .radio-item {
      padding: 0 5px !important;
    }
    label::before {
      margin: 0 3px 0 0 !important;
    }
  }
  a.nav-link.active,
  &__main > .main-report-content {
    background-color: #0a020b !important;
    // 100311
  }
  .main-tabs.navbar {
    padding: 0;
    margin: 0;
    min-height: auto;
    border: none;
    a.nav-link {
      width: 120px;
      padding: 4px 10px;
    }
  }
  &__alert {
    margin-bottom: 20px;
  }
  .main-report-content {
    padding: 20px;
    tbody td > p {
      margin: 0;
    }

    .table.table-dark.table-striped tr > * {
      text-align: right;
      &.text-align-left {
        text-align: left;
        vertical-align: middle;
      }
      &.text-align-vertical {
        vertical-align: middle;
      }
    }
  }
  .current {
    &__controls {
      display: flex;
      margin-bottom: 8px;
      & > * {
        margin-right: 23px;
        label {
          font-weight: 400;
          position: relative;
          top: -1px;
        }
        .custom-radio {
          margin-right: 0.4rem;
        }
      }
    }

    &__body {
      max-height: calc(100vh - 420px);
      overflow: auto;
    }
  }

  .past__body {
    max-height: calc(100vh - 400px);
    overflow: auto;
  }
}
