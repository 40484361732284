.help-wrapper {
  background-color: #2e1d31;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 1.5em;
  display: grid;
  grid-template-columns: 184px auto;
  height: 100vh;
  overflow: auto;

  .help__content {
    padding: 20px;
    padding-top: 5px;

    h1 {
      margin: 0 0 15px 0;
      font-family: Lato, Avenir, Verdana, Nunito;
      font-weight: normal;
      font-size: 28px;
      color: #fff;
    }

    h2 {
      font-size: 24px;
      color: #fff;
      margin: 0 0 15px 0;
      font-family: Lato, Avenir, Verdana, Nunito;
      font-weight: normal;
    }

    img {
      border: solid #ffc519 1px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .last-p {
      margin-bottom: 0;
    }

    kbd {
      font-size: 12px;
      font-family: Consolas, monospace, serif;
      color: #D05;
      white-space: nowrap;
      background-color: inherit;
      padding: 0;
    }

    table {
      & th, td {
        padding: 4px;
        border: 1px solid grey;
      }
    }
  }
}
