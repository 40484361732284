.pagination-main {
  display: flex;
  width: 100px;
  align-items: center;
  justify-content: space-between;
  &__pages {
    span {
      padding:0 5px;
    }
  }
  &__button {
    cursor: pointer;
    width: 12px;
    height: 20px;
  }
  &__button.first-page {
    background-image: url("../../static/images/pagination/doubl.svg");
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: center;
  }
  &__button.prev-page {
    background-image: url("../../static/images/pagination/single.svg");
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center;
  }
  &__button.next-page {
    background-image: url("../../static/images/pagination/single.svg");
    transform: rotate(180deg);
    background-repeat: no-repeat;
    background-size: 10px;
    background-position-y: 3px;
  }
  &__button.last-page {
    background-image: url("../../static/images/pagination/doubl.svg");
    transform: rotate(180deg);
    background-repeat: no-repeat;
    background-size: 15px;
    background-position-y: 3px;
  }
}
