.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-left: 13rem;

    &>p {
        margin: 0 10px;

        // &:first-child {
        //     margin-left: 5px;
        // }
    }
    @media (max-width: 440px) {
        flex-direction: column;
        margin-bottom: 5px;
    }

    &-container {
        bottom: 0;
        width: 100%;
        z-index: 4;
        height: 40px;;
    }
}
