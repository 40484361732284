@import '../../scss/variables.scss';

div.main-wrapper .middle .page-content .main-content {
  background: transparent;
}

.deposit-entry {
  font-family: 'Lato';
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  &__top {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
  }
  &__label {
    font-weight: 700;
    font-size: 14px;
  }
  &__top-select {
    width: 100%;
    height: 42px;
    border: 2px solid #CC42DA;
    font-weight: 400;
    font-size: 14px;
    color: #5F686B;
  }

  &__form-wrapper {
    padding: 25px 20px;
    background: #26183E;
    .deposit-entry__label {
      padding: 0 3px;
    }
  }
  &__form-title {
    font-size: 18px;
    margin-bottom: 30px;
  }
  &__form-input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  &__form-input-wrapper:not(:last-child) {
    margin-bottom: 25px;
  }
  &__form-input {
    border-radius: 0;
    height: 42px;
    font-weight: 400;
    font-size: 14px;
    color: #5F686B;
    padding: 12px 15px;
  }
  &__form-input::-webkit-input-placeholder {
    font-weight: 400;
    font-size: 14px;
    color: #5F686B;
  }
  &__form-input::-moz-placeholder {
    font-weight: 400;
    font-size: 14px;
    color: #5F686B;
  }
  &__form-button {
    width: 65px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
  &__form-button:hover {
    background: #109993;
    box-shadow: 2px 2px 0px #073e3c, inset -2px -2px 0px #095551;
  }
  &__form-reset {
    margin-right: 25px;
    background: transparent;
    color: #16C431;
  }
  &__form-save {
    background: #0F8422;
    box-shadow: inset -2px -2px 0px #0B5617;
  }
  &__form-footer {
    display: flex;
    justify-content: flex-end;
  }
  &__pop-up {
    width: 460px;
    height: 42px;
    background: #108423;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 40px;
    margin: 30px 0;
    position: relative;
  }
  &__pop-up:after {
    content: '';
    background-image: url("../../static/images/check_mark.svg");
    width: 15px;
    height: 10px;
    position: absolute;
    left: 15px;
    top: 15px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  &__top-select-custom {
    width: 100%;
    height: 42px;
    font-weight: 400;
    font-size: 14px;
    color: #5F686B;
    &-selected {
      border: 2px solid #CC42DA;
      background-color: #dcdcdc;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 20px;
      position: relative;
    }
    &-selected:after {
      content: "";
      position: absolute;
      right: 10px;
      top: 16px;
      border: 5px solid transparent;
      border-color: #5F686B transparent transparent transparent;
    }
    &-selected.active {
      background-color:#481953;
      border-color: transparent transparent #fff transparent;
      color: #FFFFFF;
    }
    &-selected.active:after {
      border-color: #FFFFFF transparent transparent transparent;
    }
    &-options {
     width: 109%;
      position: relative;
      li {
        list-style-type: none;
        padding: 10px 20px;
        background: #FFFFFF;
        border-bottom: 1px solid #DDDDDD;
        cursor: pointer;
        height: 42px;
        position: relative;
        right: 40px;
      }
      li:hover {
        background-color: #dcdcdc;
        color: #ffffff;
      }
    }
  }
}
