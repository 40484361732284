.deposit-pop-up {
    width: 450px;
    height: auto;
    background: #26183E;
    padding: 25px 20px 25px 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    &-title{
      text-transform: capitalize;
      margin-right: auto;
      color: #ffffff;
      font-weight: 400;
      font-size: 18px;
    }
    .deposit-pop-up-info {
      width: 100%;
      &-title{
        font-weight: 700;
        font-size: 14px;
        margin-top: 15px;
        margin-bottom: 5px;
      }
      &-row{
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-item{
          font-size: 14px;
          width: calc((100% - 10px)/2);
          background: #DDDDDD;
          height: 42px;
          color: #5F686B;
          display: flex;
          justify-content: end;
          align-items: center;
          padding-right: 16px;
        }
      }
      .error-message {
        color: #b71502;
      }
      input{
        width: 100%;
        height: 42px;
        border-width: 0;
        padding-left: 15px;
      }
      input.error {
        border: 2px solid #b71502;
      }
      input:focus-visible {
        outline: none;
        border: 2px solid #cc42da !important;
      }
      textarea{
        width: 100%;
        height: 135px;
      }
     .deposit-pop-up-info-item {
       display: grid;
       grid-template-columns: auto 1fr auto;
       column-gap: 20px;
       align-items: center;
       .title {
           font-weight: 700;
           font-size: 18px;
       }
       .value{
         font-weight: 400;
         font-size: 14px;
         }
       .copy-img {
         display: inline-block;
         width: 30px;
         height: 30px;
         cursor: pointer;
         position: relative;
       }
       .copy-img:after {
         content: "";
         background-color: #FFFFFF;
         mask-image: url("../../static/images/copied.png");
         mask-repeat: no-repeat;
         mask-size: 22px;
         mask-position: center;
         width: 30px;
         height: 30px;
         position: absolute;
         transition: all 0.3s;
       }
       .copy-img:hover {
         &:after {
           background-color: #cae0e8;
           scale: 1.1;
         }
       }
       .copy-img:active {
         &:after {
           background-color: #cae0e8;
          scale: 0.8;
         }
       }
      }
      .deposit-pop-up-info-item:last-child {
        margin-top: 40px;
      }
    }
    .deposit-pop-up-button {
      width: 115px;
      height: 45px;
      background: #0F8422;
      box-shadow: inset -2px -2px 0px #0B5617;
      margin-left: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s;
      span {
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
      }
    }
    .deposit-pop-up-button:hover {
      transform: translate(-1px, -1px);
      box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.25);
      background: #408b36;
    }
    .deposit-pop-up-button:active {
        transform: translate(1px, 1px);
        box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.25);
      }
      &-buttonsRow{
        display: flex;
        justify-content: end;
        margin-top: 25px;
        align-items: center;
        width: 100%;
        &-back{
          cursor: pointer;
          font-weight: 400;
          font-size: 16px;
          color: #16C431;
        }
      }
  }
  
  .new-ma__information__copy-button {
    width: 100%;
    height: 32px;
    background: #3C8132;
    box-shadow: inset -2px -2px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 25px;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
    }
  }
  .new-ma__information__copy-button:hover {
    transform: translate(-1px, -1px);
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.25);
    background: #408b36;
  }
  .new-ma__information__copy-button:active {
    transform: translate(1px, 1px);
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.25);
  }

  .new-ma__information__pass-input-wrapper {
    position: relative;
    .custom-text-input_white {
      pointer-events: none;
    }
    .new-ma__information__pass-generate-2 {
      width: 32px;
      height: 32px;
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(-40%, 60%);
      z-index: 1;
      cursor: pointer;
      transition: all 0.5s;
    }
    .new-ma__information__pass-generate-2:after {
      content: "";
      background-color: #3C8132;
      mask-image: url("../../static/images/reload-pass.svg");
      mask-repeat: no-repeat;
      mask-size: 15px;
      mask-position: center;
      width: 32px;
      height: 32px;
      position: absolute;
      margin-top: 0.75rem;
    }
    .new-ma__information__pass-generate-2.active:after {
      animation: circle 0.4s;
    }
    @keyframes circle{
      0%{
        transform: rotate(0deg);
        //mask-size: 16px;
      }
      60%{
        transform: rotate(360deg);
        //mask-size: 8px;
      }
      100%{
        transform: rotate(360deg);
        //mask-size: 16px;
      }
    }
  }
