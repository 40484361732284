.header {
  width: 100%;
  height: 80px;
  font-size: 14px;
  background-color: #27072b;
  display: flex;
  position: fixed;
  z-index: 2;
  height: 80px;

  &-content {
    flex-grow: 1;
    display: flex;
    align-content: center;
    justify-content: space-between;

    &__left,
    &__right {
      display: flex;
      align-content: center;
    }

    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12.86em;
      margin-left: 10px;

      img {
        width: 100%;
        cursor: pointer;
      }
    }

    &__date-time {
      display: flex;
      align-items: center;
      margin: 0 10px;
      color: white;

      .date {
        margin-right: 7px;
      }
      .time {
        margin-right: 7px;
        font-size: 20px;
        font-weight: bold;
        position: relative;
        bottom: 2px;
        left: 1px;
      }
      .time-control {
        position: relative;
        bottom: 0;
        bottom: 1px;
        left: 1px;

        & > .dropdown-menu {
          border-radius: 7px !important;
          font-weight: 400;
          color: #000000;
          top: 6px !important;
          left: -76px !important;
          padding: 0;

          a {
            padding: 9px 16px;
          }

          a:not(:last-child) {
            border-bottom: 1px solid #dddddd;
          }
        }
      }
    }

    &__login-info {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding-left: 20px;
      padding-right: 50px;
      p {
        margin: 0;
      }
    }

    &__controls {
      display: flex;
      flex-direction: row;

      .controls__dropdown {
        .dropdown-menu {
          left: -33px !important;
          top: 4px !important;
          padding: 0;
        }
      }
    }

    .controls__button,
    .controls__dropdown {
      display: flex;
      align-items: center;
      margin-right: 20px;
      padding: 10px;
      color: white;
      font-size: 14px;
      position: relative;

      svg.mdi-icon {
        width: 20px;
        height: 20px;
      }

      // &:last-child {
      //   margin-right: 10px;
      // }
    }
    .controls__button-help,
    .controls__button-settings,
    .controls__button-logout {
      position: relative;
      padding-left: 18px;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 20px;
        height: 20px;
        background: url("../../static/images/info.svg") center/7px no-repeat;
      }
    }
    .controls__button-logout {
      &:before {
        left: -5px;
        width: 20px;
        background: url("../../static/images/logout.svg") center/16px no-repeat;
      }
    }
    .controls__button-settings {
      top: unset;
      &:before {
        left: -6px;
        width: 20px;
        background: url("../../static/images/settings.svg") center/16px no-repeat;
      }
    }

    @media (min-width: 992px) {
      &__controls {
      }

      &__menu {
        display: none;
      }
    }

    @media (max-width: 992px) {
      &__controls {
        display: none;
      }

      &__menu {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .menu-icon {
          cursor: pointer;
        }
        .menu__dropdown {
        }
      }
    }

    @media (max-width: 636px) {
      .time-control > .dropdown-menu {
        transform: translate(-100px, 25px) !important;
        &::before {
          right: 9%;
        }
      }
    }

    @media (max-width: 520px) {
      &__date-time > .date {
        display: none;
      }

      .time-control > .dropdown-menu {
        transform: translate(-80px, 25px) !important;
        &::before {
          right: 18%;
        }
      }
    }

    @media (max-width: 420px) {
      &__left {
        flex-wrap: wrap;
      }
      &__date-time {
        position: relative;
        left: 50px;
      }
      &__logo {
        position: relative;
        top: 15px;
      }

      .time-control > .dropdown-menu {
        transform: translate(-30px, 25px) !important;
        &::before {
          right: 38%;
        }
      }
    }
  }
}

.bm-burger-button {
  z-index: 1 !important;
  position: relative;
  width: 36px;
  height: 30px;
  &:focus,
  &:active {
    outline: none;
  }
}

.bm-burger-bars {
  background: white;
}

.bm-burger-bars-hover {
  opacity: 0.6;
}

.bm-cross-button {
  width: 100% !important;
  height: 30px !important;
  left: 0;
  top: 0 !important;
  box-sizing: content-box;
  padding: 5px 0;
  background-color: #938495;
}

.bm-cross {
}

.bm-menu-wrap {
  top: 85px;
  z-index: 2 !important;
}

.bm-menu {
  background: #1a051c;
  padding: 2.5em 0 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
  .controls__button,
  .controls__dropdown {
    background-color: #3c2b3e;
    margin: 0;
  }
  .controls__dropdown:hover {
    background-color: #311e34;
  }
  .header-dropdown {
    width: 100%;
    &__toggle {
      width: 100%;
      text-align: start;
    }
  }
}

.bm-item {
  display: inline-block;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  z-index: 2 !important;
}

.controls__button {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.controls__dropdown:hover {
  cursor: pointer;
  opacity: 1 !important;
  .header-dropdown__toggle {
    opacity: 0.6;
  }
}

.wrapper {
  transition: top 0.1s;
  top: 17px;
  opacity: 1;
  z-index: 10002;
  padding: 6px;
  width: 41px;
  height: 42px;
  left: 50%;
  background: #fff;
  border-radius: 50%;
  z-index: -1;
  box-shadow: 1px 1px 25px #ffffff66;
  animation: blink-shadow 1s linear infinite;
  position: fixed;
  text-align: center;
  pointer-events: none;

  div {
    position: relative;
    width: 29px;
    height: 29px;
    -webkit-animation: circle infinite 0.5s linear;
    -moz-animation: circle infinite 0.5s linear;
    -o-animation: circle infinite 0.5s linear;
    animation: circle infinite 0.5s linear;
    border: 2px solid #fff;
    border-top-color: #8e1e98;
    border-right-color: #8e1e98;
    border-bottom-color: #8e1e98;
    border-radius: 100%;
  }
}

@keyframes circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes blink-shadow {
  0% {
    box-shadow: 1px 1px 35px #ffffffcc;
  }

  50% {
    box-shadow: 1px 1px 0 #ffffff1a;
  }
  100% {
    box-shadow: 1px 1px 35px #ffffffcc;
  }
}
