.workstation {
  align-items: center;
  button {
    margin-left: 10px;
    height: 32px;
    background: #8d1e98;
    box-shadow: inset -2px -2px 0px #65166d;
  }
  .sport-radios {
    .radio-item:nth-child(3)::before {
      content: "|";
      position: absolute;
      left: -5px;
      font-weight: bold;
    }
  }
  .custom-dropdown {
    button {
      width: 270px;
    }
    .dropdown-menu:not(.show) {
      display: none;
    }
    .dropdown-item {
      width: 270px;
      height: auto;
      span {
        white-space: normal;
      }
    }
  }
}
.workstation-container {
  .back-net-exposure {
    color: #2ae5dd;
    font-size: 14px;
    margin-bottom: 10px;
    width: max-content;
    cursor: pointer;
  }
  .table-workstation {
    display: flex;
    flex-wrap: wrap;
    > div {
      padding: 0 10px 10px 10px;
      background-color: #3a0c3e;
      overflow-x: auto;
      height: max-content;
      &:nth-child(1) {
        flex-grow: 1;
        margin: 0 10px 10px 0;
        overflow-y: auto;
        max-height: 300px;
        min-width: 500px;
      }
      &:nth-child(2) {
        margin-bottom: 10px;
        flex-grow: 10;
        overflow-y: auto;
        max-height: 300px;
      }
      > p {
        margin: 7px 0 10px 0;
        font-weight: bold;
        font-size: 13px;
        line-height: 20px;
      }
    }
    .table-1 {
      table-layout: auto;
      position: relative;
      td {
        height: 50px;
        width: 100%;
        color: black;
        padding: 7px;
        text-align: center;
        border: 1px solid #9c9c9c;
        min-width: 54px;
        > div:nth-child(1) {
          font-weight: bold;
        }
        &:nth-child(1) {
          color: white;
          text-align: left;
          > div:first-child {
            max-width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &.white {
          background-color: #e3f3fe;
          &:nth-child(4) {
            background-color: #a6d8fb;
          }
          .price {
            color: #000;
            font-size: 10px;
            display: block;
          }
        }
        &.triplet {
          background-color: #fdeef2;
          &:nth-child(5) {
            background-color: #fac9d4;
          }
          .price {
            color: #000;
            font-size: 10px;
            display: block;
          }
        }
        &.Info {
          background-color: #ddd;
          min-width: 150px;
          position: absolute;
        }
        &.InfoBall {
          background-color: #ddd;
          width: 110px;
          height: 100%;
          position: absolute;
          font-weight: bold !important;
          right: 0;
          opacity: 0.9;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .table-1.INNINGS_RUNS {
      td {
        &:nth-child(1) {
          > div:first-child {
            max-width: 300px !important;
          }
        }
        &.white {
          background-color: #a6d8fb;
        }
        &.triplet {
          background-color: #fac9d4;
        }
        // &.isBettable {
        //   opacity: 0.6;
        // }
      }
    }
 
    .table-2 {
      table-layout: auto;
      width: 100%;
      min-width: 415px;
      tr {
        td:not(:nth-child(2)) {
          min-width: 80px;
        }
        td:nth-child(3) {
          padding-left: 28px;
          width: 23%;
        }
        > td:first-child {
          padding-left: 13px;
          width: 25%;
        }
      }
      thead {
        tr {
          font-weight: bold;
          td {
            padding-top: 5px;
          }
        }
      }
      tbody {
        .indent {
          background-color: #3a0c3e;
          height: 5px;
        }
        .title-ladder {
          border: 1px solid #ddd !important;
          background-color: #b9cce7 !important;
          color: black;
          td {
            text-align: center;
          }
        }
        .body-ladder {
          background-color: #ffffff;
          color: black;
          padding: 0;
          td {
            width: 50%;
            border: 1px solid #ddd;
            text-align: center;
          }
        }
        tr {
          td {
            span {
              color: white !important;
            }
            height: 28px;
          }
          background-color: #4e2451;
          &:first-child {
            padding-left: 13px;
            background-color: #deeaf6;
            color: black;
            span {
              color: black !important;
            }
            > td:nth-child(2) {
              width: 72px;
              td {
                &.none {
                  background-color: #deeaf6 !important;
                }
                &:nth-child(1) {
                  min-width: 36px;
                  background-color: #485096;
                }
                &:nth-child(2) {
                  height: 26.7px;
                  min-width: 36px;
                  background-color: #2f3462;
                }
              }
              img {
                cursor: pointer;
              }
            }
          }
          td:nth-child(2) {
            text-align: center;
            td {
              &:nth-child(1) {
                min-width: 36px;
              }
              &:nth-child(2) {
                min-width: 36px;
              }
            }
          }
          &:hover:not(:first-child):not(.body-ladder):not(.indent) {
            background-color: rgba(255, 255, 255, 0.2);
          }
          &.join {
            > td:first-child {
              padding-left: 60px !important;
            }
          }
        }
      }
    }
    .table-3 {
      width: 100%;
      padding: 0 10px;
      overflow-y: auto;
      .text-align-right {
        text-align: right;
      }
      .message {
        padding: 5px 0;
        text-align: center;
      }
    }
  }
}
