.help__sidebar-menu {
    height: 100%;
    padding: 12px 12px 22px 2px;
    width: 184px;
    min-width: 184px;
    color: #fff;
    border-right: 1px solid #fff;

    .help-sidebar-menu__ul {
      padding: 5px 20px;

      &2, &3 {
        margin-left: 10px;
        padding: 5px 0;
        list-style-type: disc;
      }
      a {
        &:hover {
          opacity: 0.6;
        }
      }
    }
}