.custom-buttom {
    padding: 0 0.9rem;;
    color: white;
    border: 0;

    &:focus {
        outline: none;
    }

    span {
        // padding: 7px 13px;
    }

    &.purple {
        background: #8d1e98;
        box-shadow: inset -2px -2px 0px #65166d;

        &:hover {
            background: #a222ad;
            box-shadow: 2px 2px 0px #521158, inset -2px -2px 0px #66166d;
        }

        &:active {
            background: #a222ad;
            box-shadow: inset 2px 2px 0px #66166d;
        }

        &:disabled {
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #444444;
    
            background: #222222;
            box-shadow: unset;
        }
    }

    &.green {
        background: #0f8422;
        box-shadow: inset -2px -2px 0px #0b5617;

        &:hover {
            background: #119b29;
            box-shadow: 2px 2px 0px #063f11, inset -2px -2px 0px #0b5617;
        }
        &:active {
            background: #0f8422;
            box-shadow: inset 2px 2px 0px #0b5617;
        }
    }

    &.red_hover {
        background: #D01A00 !important;
        box-shadow: 2px 2px 0px #6B0C01, inset -2px -2px 0px #841102 !important;
    }
    
    &.red_active {
        background: #b71502 !important;
        box-shadow: inset 2px 2px 0px #841102 !important;
    }

    &.red {
        background: #B71502 !important;
        box-shadow: inset -2px -2px 0px #841102 !important;

        &:hover {
            @extend .custom-buttom.red_hover;
        }

        &:active {
            @extend .custom-buttom.red_active;
        }
    }

    &.cyan_hover {
        background: #109993;
        box-shadow: 2px 2px 0px #073e3c, inset -2px -2px 0px #095551;
    }
    
    &.cyan_active {
        background: #10827d;
        box-shadow: inset 2px 2px 0px #0a504d;
    }

    &.cyan {
        background: #10827d;
        box-shadow: inset -2px -2px 0px #0a5551;

        &:hover {
            @extend .custom-buttom.cyan_hover;
        }

        &:active {
            @extend .custom-buttom.cyan_active;
        }

        &:disabled {
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #444444;
    
            background: #222222;
            box-shadow: unset;
        }
    }

}


