.table-downline {
  .refresh {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      margin-right: 10px;
    }
    border-bottom: 1px solid white;
  }
  .table {
    color: white;
    max-height: 670px;
    overflow-y: auto;
    table {
      border-collapse: collapse;
      td {
        padding: 4px 8px;
      }
      width: 100%;
      .radio-item {
        display: inline-flex;
        margin-right: 20px;
        label {
          margin-bottom: 0;
        }
      }
      thead {
        td {
          padding: 15px 8px;
          &:first-child {
            width: 24%;
          }
        }
      }
      tbody {
        tr {
          &.body-ladder {
            td: {
              border: 1px solid #ddd !important;
            }
          }
          td {
            width: 16%;
            background-color: #e6f2ff;
            vertical-align: middle;
            color: black;
            &.marketName {
              background: #5059a7;
              color: white;
            }
            &.tabe-icon {
              padding: 0;
              img {
                cursor: pointer;
                height: 29px;
                width: 29px;
                padding: 8px;
              }
            }
          }
        }
        .nesting {
          padding: 10px 0;
          width: 100%;
          span:not(:last-child) {
            color: #2ae5dd;
            cursor: pointer;
            position: relative;
            &::after {
              content: ">";
              color: white;
              margin: 0 5px;
            }
          }
        }
        .title {
          span {
            color: #3cd247;
          }
          background-color: #44217f;
          width: 100%;
        }
        .title-ladder {
          border: 1px solid #ddd;
          border-bottom: none;
          color: black;
          td {
            background-color: #b9cce8;
            text-align: center;
          }
        }
        .body-ladder {
          color: black;
          padding: 0;
          td {
            background-color: #ffffff;
            border: 1px solid #ddd !important;
            text-align: center;
          }
        }
        .join {
          border-bottom: 0 !important;
          border-left: 60px solid #180519 !important;
          &:nth-child(even) {
            td {
              background-color: #180519 !important;
            }
          }
        }
        .list {
          &:hover {
            > td {
              background: rgba(255, 255, 255, 0.2) !important;
            }
          }
          > td {
            height: 33px;
            vertical-align: middle !important;
            background-color: #f5f5f51a;
            color: white;
            &.tabe-icon {
              padding: 0 !important;
              img {
                cursor: pointer;
                height: 29px;
                width: 29px;
                padding: 8px;
              }
            }
          }
        }
      }
    }
  }
}
