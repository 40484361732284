.new-member-pop-up {
  width: 450px;
  height: 400px;
  background: #26183E;
  padding: 80px 20px 130px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .new-member-pop-up-info {
   .new-member-pop-up-info-item {
     display: grid;
     grid-template-columns: auto 1fr auto;
     column-gap: 20px;
     align-items: center;
     .title {
         font-weight: 700;
         font-size: 18px;
     }
     .value{
       font-weight: 400;
       font-size: 14px;
       }
     .copy-img {
       display: inline-block;
       width: 30px;
       height: 30px;
       cursor: pointer;
       position: relative;
     }
     .copy-img:after {
       content: "";
       background-color: #FFFFFF;
       mask-image: url("../../static/images/copied.png");
       mask-repeat: no-repeat;
       mask-size: 22px;
       mask-position: center;
       width: 30px;
       height: 30px;
       position: absolute;
       transition: all 0.3s;
     }
     .copy-img:hover {
       &:after {
         background-color: #cae0e8;
         scale: 1.1;
       }
     }
     .copy-img:active {
       &:after {
         background-color: #cae0e8;
        scale: 0.8;
       }
     }
    }
    .new-member-pop-up-info-item:last-child {
      margin-top: 40px;
    }
  }
  .new-member-pop-up-button {
    width: 115px;
    height: 45px;
    background: #0F8422;
    box-shadow: inset -2px -2px 0px #0B5617;
    margin-top: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
    span {
      font-weight: 400;
      font-size: 16px;
      color: #FFFFFF;
    }
  }
  .new-member-pop-up-button:hover {
    transform: translate(-1px, -1px);
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.25);
    background: #408b36;
  }
  .new-member-pop-up-button:active {
      transform: translate(1px, 1px);
      box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.25);
    }
}
