.transfer-page {
  padding-top: 7px;
  padding-bottom: 20px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;

  .page-header {
    margin-bottom: 9px;
  }

  .table {
    text-align: end;
    .text-align-center {
      text-align: center;
    }
    td a {
      margin: 0;
      color: #2ce5dd;
      cursor: pointer;
      height: 36px;
      &:hover {
        color: #74f5ee;
      }
      box-shadow: none !important;
      &.disabled {
        cursor: default;
        color: #777777;
      }
    }
  }
}
