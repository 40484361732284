.high-low-betting {
  width: 100%;
  min-width: 250px;
  display: flex;
  margin: -15px 0 40px 10px;
  justify-content: space-between;
  &__games {
    display: flex;
  }
  &__games-item {
    display: flex;
    flex-direction: column;
    min-width: max-content;
    margin-right: 50px;
    &-title {
      text-align: center;
    }
  }
  &__games-item-cards {
    padding-top: 7px;
    img {
      width: 32px;
      height: 44px;
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
    }
  }

  &__info-column {
    min-width: 140px;
  }
  &__info-row {
    width: 100%;
    display: flex;
    font-size: 10px;
    &-title {
      width: 60%;
      text-align: right;
      font-weight: 400;
      color:  #C4C4C4;
    }
    &-results {
      width: 40%;
      font-weight: 500;
      color: #FFFFFF;
      padding-left: 4px;
    }
  }
}

@media (max-width: 1024px) {
  .high-low-betting {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin: -15px 0 5px 0px;
    &__games {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__games-item {
      display: flex;
      min-width: max-content;
      align-items: flex-start;
      flex-direction: column;
      &-title {
        font-size: 10px;
        font-weight: 500;
        padding: 0 0 10px 7px;
        span:last-child {
          display: none;
        }
      }
    }
    &__games-item:last-child {
      margin-right: unset;
    }
    &__games-item-cards {
      display: flex;
      padding-top: unset;
      img {
        width: 26px;
        height: 35px;
        filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
        margin-left: 5px;
      }
    }
    .high-low-betting__info-column {
      padding-top: 30px;
      .high-low-betting__info-row-title {
        width: unset;
      }
    }
  }
}
