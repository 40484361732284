.custom-alert {
    margin: 10px;
    width: calc(100% - 20px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000000;

    &__container {
        width: fit-content;
        max-width: 80%;
        padding: 20px;
        margin: 0 auto;
        position: relative;
        top:-80px;
        transition: 300ms;

        &.active {
            top: 0;
        }
        
        &.alert_red {
            background: #ff4433;
            // width: 1112px;
        }

        &.alert_green {
            background: #108423;
            // width: 492px;
        }
    }

    p {
        width: fit-content;
        font-style: normal;
        font-weight: bold;
        font-size: 1rem;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
        margin: 0;
    }
}
