.bet-list-page {
  padding-top: 6px;
  h1 {
    // width: 8rem;
    margin-right: 20px;
    position: absolute;
    padding-top: 2px;
  }

  .bet-list-report {
    .page-header {
      padding-bottom: 3px;
      margin-bottom: 10px;
      &__main {
        margin-left: 8.6rem;
        #DateRangePickerChildren {
          padding: 0;
          #formControlsTextB {
            padding: 8px;
          }
        }
        & > button.custom-buttom {
          position: relative;
          top: 2px;
        }
        & > * {
          margin-bottom: 4px;
        }
      }
      &__filters {
        padding-top: 3px;
        & > * {
          margin-bottom: 3px;
        }
      }
    }
    table tbody td {
      vertical-align: middle;
    }
    &__past {
      .past__body {
        max-height: calc(100vh - 400px);
      }
    }
    &__main .main-report-content,
    a.nav-link.active {
      background-color: #100311 !important;
    }

    a.nav-link {
      margin-right: 3px;
    }
  }
}
