@import '../../scss/variables.scss';

div.main-wrapper .middle .page-content .main-content {
  background: transparent;
}
.payment-reports {
  font-family: 'Lato';
  width: 100%;
  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  &__header_date-row {
    display: flex;
    h1 {
      width: 30%;
      color: #FFFFFF;
    }
  }
  &__header_date-unput-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: 1fr 1fr 1.2fr 1fr;
    column-gap: 5px;
    margin: 5px 0;
    &-drop-down-wrapper {
      width: 168px;
    }
    .custom-text-input_black {
      input {
        min-width: 145px;
      }
    }
  }
  &__header-search-column {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &-buttons {
      display: flex;
      width: 50%;
      justify-content: center;
    }
    &-search-wrapper {
      width: 318px;
    }
  }
  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  &__table {
    &-row-date {
     display: flex;
      justify-content: space-between;
      margin: 5px 0;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
    }
    &-header {
     display: flex;
      li {
        width: calc(100% / 9);
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        color: #849398;
      }
    }
    &-body {
      margin-top: 10px;
      ul {
        background-color: #2E1D30;
      }
      ul:nth-child(even) {
        background-color: #170519;
      }
    }
    &-body-row {
      display: flex;
      align-items: center;
      li {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% / 9);
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
      }
      &-date {
        display: flex;
        flex-direction: column;
      }
      &-date div.time {
        color: #849398;
      }
      &-id {
       cursor: pointer;
        transition: all 0.3s;
      }
      &-id:hover {
        transform: translate(-1px, -1px);
        text-shadow: 0 0 1px white;
        opacity: 0.6;
      }
    }
  }
}
