.userCount-report {
    background-color: #0A020B;
    padding: 10px 20px;
    .table {
        text-align: end;
        max-width: 30rem;
        tr th {
            text-align: left;
        }
        th, td {
            padding: 4px 10px;
        }
    }
}
.userCount-title{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 20px;
    color: #CAE0E8;
    margin-right: 204px;
    display: flex;
    align-items: center;
}