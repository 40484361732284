.dropdown-range {
    button {
        position: relative;
        width: 145px;
        height: 32px;
        background: #37133f;
        border: 0;
        color: white;
        padding-left: 12px;
        padding-right: 20px;
        text-align: start;
        font-size: 14px;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-radius: 0;

        &:hover {
            background: #37133f;
        }

        &:focus {
            outline: none;
            background: #481953;
            border: 0;
            box-shadow: none;
        }

        .custom-dropdown__icon {
            position: absolute;
            right: 3px;
            top: 4px;
        }

        &.btn-primary.dropdown-toggle {
            outline: none;
            background: #481953;
            border: 0;
            box-shadow: none;
            &:focus {
                box-shadow: none;
            }
            &::after {
                position: absolute;
                right: 9px;
                top: 14px;
            }
        }
    }

    .dropdown-menu.show {
        display: flex !important;
        border-radius: 0;
        top: -3px !important;
        left: -2px !important;
        color: #60686b;
        & > div {
            padding: 8px 20px;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            label {
                margin: 0 8px 0 0;
            }
            input {
                background-color: #ececec;
                color: #000;
                text-align: center;
                border: none;
                width: 100px;
                padding: 5px;
                margin-left: 8px;
                &:focus, &:active {
                    border: none;
                    box-shadow: none;
                    outline: none;
                }
            }
        }
    }
}
