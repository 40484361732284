.status-dropdown {
    width: 100%;
    height: 34px;
    min-width: 168px;
    font-weight: 400;
    font-size: 14px;
    color: #5F686B;
    &-selected {
        background-color: #2E1D30;;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 15px;
        position: relative;
    }
    &-selected:after {
        content: "";
        position: absolute;
        right: 10px;
        top: 16px;
        border: 5px solid transparent;
        border-color: #FFFFFF transparent transparent transparent;
    }
    ul, li {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    &-options {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        position: relative;
        li {
            padding: 5px 15px;
            background: #2E1D30;
            cursor: pointer;
            height: 32px;
            margin: 2px 0;
        }
    }
}
