.activity-report {
    &__controls {
        background-color: #0A020B;
        display: flex;
        margin-bottom: 20px;
        .radio-item {
            margin-right: 20px;
        }
        label {
            margin: 0;
        }

    }
    .table {
        text-align: end;
        tr th, tr th:hover {
            background-color: #0E0310;
            font-weight: 400;

        }
    }
}