.pnl-by-agent {
  padding-top: 9px;
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 3px;
    margin-bottom: 7px;

    &__group {
      display: flex;
      h1 {
        width: 17rem;
        position: relative;
        top: -2px;
        margin-right: 56px;
      }
      .date-time-range__box {
        height: fit-content;
      }
    }
  }
  &__main {
    .main-page-content {
      overflow: auto;
      max-height: calc(100vh - 350px);
      margin-top: 28px;

      .table {
        thead tr {
          text-align: end;
          &:first-child {
            text-align: center;
          }
          th {
            padding: 5px;
          }
        }
        tbody {
          td {
            text-align: end;
            &.event-name {
              background-color: #6b5f6c;
              text-align: start;
              padding-left: 16px;
              &:hover {
                background-color: #968c97c5 !important;
              }
            }

            &.agency-name {
              text-align: start;
              padding-left: 24px;
            }
          }
          th {
            text-align: end;
          }
          .delimiter-row {
            background-color: #706572;
            &:hover {
              background-color: #aa9eacc5 !important;
            }
          }
        }
      }

      .border_right-side {
        border-right: 1px solid #ddd !important;
      }
    }
  }
  &__view-bets {
    .downline-nav {
      margin: 17px 0 21px;
    }
  }
  .downline-nav svg {
    margin: 0 3px;
  }
}
