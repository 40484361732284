@import "../SecretRoom/BetListReport/index.scss";
@import "../PnLByAgentPage/index.scss";

.pnl-by-market {
    padding-top: 7px;
    @extend .bet-list-report;

    .page-header {
        padding-bottom: 0;
        &__main {
            h1 {
                margin-right: 62px;
            }
            & > div {
                align-items: flex-start;
                & > div {
                    margin-top: 2px;
                }
            }
            #pnl-by-market__filter:checked + .filter__toggle {
                background-color: #ffffff;
                color: #000000;
            }
        }
    }
}
