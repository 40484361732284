.pnl-by-agent__view-bets .breackdown-statement {
    padding-top: 7px;
    .page-header {
        padding-bottom: 5px;
        margin-bottom: 17px;
        button {
            margin: 7px 0 0;
        }
    }
    .nav {
        margin-bottom: 28px;
    }
}