.search-user {
    position: relative;
    input {
        margin: 0;
        max-width: 165px;
        height: 32px;
        padding: 0.75rem 0.7rem;
        &::placeholder {
            font-size: 1rem;
        }
    }
    .dropdown.btn-group {
        position: absolute;

        #dropdown-invisible-1 {
            position: absolute;
            display: none;
        }
        & .dropdown-menu {
            position: absolute;
            border-radius: 0;
            border: 1px solid #dddddd;
            border-top: none;

            transform: none !important;
            top: 42px !important;
            &__container {
                width: 300px;
                max-height: 500px;
                overflow: auto;
            }
            &::before {
                position: absolute;
                top: -20px;
                height: 0;
                width: 0;
                left: 20px;
                border: 10px solid transparent;
                border-bottom-color: #fff;
                content: "";
            }
            &.show {
                opacity: 1 !important;
            }
            .dropdown-item {
                display: flex;
                padding: 0;
                flex-wrap: nowrap;
                .user-name {
                    line-height: 1.2;
                    font-size: 12px;
                    padding: 12px 10px;
                    color: #727272;
                    box-sizing: border-box;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    cursor: pointer;
                    width: 100%;
                    outline: 0;
                }
                .level {
                    padding: 9px 10px;
                    text-align: end;
                    &.M {
                        color: #ffcb1c;
                    }
                    &.A {
                        color: #1cc9c5;
                    }
                    &.MA {
                        color: #1b51b2;
                    }
                    &.SMA {
                        color: #cc1b6d;
                    }
                }
            }
        }
    }
}