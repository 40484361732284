.baccarat-betting {
  margin-right: 30px;
  width: 100%;
  display: flex;
  padding: 20px 0 40px;
  &__games {
    display: flex;
    padding-left: 20px;
  }
  &__games-item {
    display: flex;
    flex-direction: column;
    min-width: max-content;
    margin-right: 50px;
    align-items: center;
  }
  .rotate-card {
    .baccarat-betting__games-item-cards{
      display: flex;
      flex-direction: row-reverse;
      img:nth-child(3) {
        transform: rotate(-90deg);
        margin-right: 10px;
      }
    }
  }
  .rotate-card-banker {
    .baccarat-betting__games-item-cards{
      display: flex;
      flex-direction: row;
      img:nth-child(3) {
        transform: rotate(-90deg);
        margin-left: 10px;
      }
    }
  }
  &__games-item-cards {
    padding-top: 10px;
    margin-bottom: 15px;
    img {
      width: 32px;
      height: 44px;
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
      margin: 0 3px;
    }
  }
  .winnerCup {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
      background-image: url(../../../static/images/Trophy.svg);
      background-position: center;
      height: 50px;
      width: 50px;
      background-repeat: no-repeat;
      background-size: 20px;
    }
  }
  &__info-column {
    min-width: 140px;
  }
  &__info-row {
    width: 100%;
    display: flex;
    font-size: 10px;
    &-title {
      width: 60%;
      text-align: right;
      font-weight: 400;
      color: #C4C4C4;
      white-space: nowrap;
    }
    &-results {
      width: 40%;
      font-weight: 500;
      color: var(--white);
      padding-left: 4px;
    }
  }
}

@media (max-width: 1024px) {
  div.baccarat-betting {
    flex-direction: column;
    padding-bottom: 15px;

    .baccarat-betting__games {
      padding: 0;
      flex-direction: column;
    }
    .baccarat-betting__games-item {
      margin: 0;
    }
    .baccarat-betting__info-column {
      margin-top: 30px;
    }
    .baccarat-betting__info-row-title,
    .baccarat-betting__info-row-results {
      width: unset;
    }
    .baccarat-betting__games-item-cards {
      img {
        width: 26px;
        height: 36px;
      }
    }
    .winnerCup::before {
      top: 4px;
      background-size: 36px;
    }
    .baccarat-betting__games-item:first-child {
      .winnerCup::before {
        top: 4px;
        left:unset;
        right: 0;
        transform: translateX(100%);
      }
    }
    .baccarat-betting__games-item.rotate-card-banker {
      .winnerCup::before {
        top: 4px;
        left: 0px;
        transform: translateX(-100%);
      }
    }
  }
}
