.downline-nav {
    i {
        font-style: italic;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
    }
    a {
    font-style: italic;

    }
    div, p {
        display: inline-block;
    }
}
